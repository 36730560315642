import React, { Component } from "react";

import services from "../services/services";
import Swal from "sweetalert2";
import MaterialTable, { MTableToolbar } from '@material-table/core';
import '../style.css';
import Select, { components } from 'react-select'
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import MultiSelect from "@khanacademy/react-multi-select";
import { NavLink } from "react-router-dom";
import Geocode from "react-geocode";
import moment from 'moment';
import $ from 'jquery';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import {
    Async,
    FieldFeedback,
    FieldFeedbacks,
    FormWithConstraints,
    Input
} from 'react-form-with-constraints';
import { de } from "date-fns/locale";

import XLSX from "xlsx-color";
import * as htmlToImage from 'html-to-image';
import jsPDF from 'jspdf';
import download from "downloadjs";
const headerTblStyle = { color: 'black' };
const exportToExcel = (columns, data) => {
    debugger;
    const header = ["Sr.No", "BookingStatus", "CountryName", "StateName", "CityName", "VehicleNo", "BookingNo", "BookingType", "ZoneName", "DistrictName", "MuncipalityName", "WardName", "Locality", "Priority", "DrainageLine150to300mmDiaMeter", "DrainageLine301to600mmDiaMeter", "DrainageLine601to900mmDiaMeter", "DrainageLine901to1200mmDiaMeter", "Remarks", "BookingDate","PaymentReceivedDate","BillNo","PaymentAmount"];
    const headertemp = ["Sr.No", "Status", "Country Name", "State Name", "City Name", "Vehicle No", "Task No", "Task Type", "Zone Name", "DistrictName", "Muncipality Name", "Ward Name", "Locality", "Priority", "150 to 300mm DiaMeter", "301 to 600mm DiaMeter", "601 to 900mm DiaMeter", "901 to 1200mm DiaMeter", "Remarks", "Task Date","Payment Date","Bill No","Amount"];
    debugger
    const compatibleData = data.map((row, i) => {
        const obj = {};
        console.log("=====>ColRow---->", row);
        header.forEach((col, index) => {
            
            if (col === "Sr.No") {
                obj[col] = i + 1;
            }
            else if (col == "BookingStatus") {
                obj["Status"] = row[col];
            }
            else if (col == "CountryName") {
                obj["Country Name"] = row[col];
            }
            else if (col == "StateName") {
                obj["State Name"] = row[col];
            }
            else if (col == "CityName") {
                obj["City Name"] = row[col];
            }
            else if (col == "VehicleNo") {
                debugger;
                if (row["BookingTaskDetail"] != null) {
                    obj["Vehicle No"] = row["BookingTaskDetail"][0]["VehicleNo"]
                }
                else {
                    obj["Vehicle No"] = "";
                }
                // obj["City Name"]=row[col];
            }
            else if (col == "BookingNo") {
                obj["Task No"] = row[col];
            }
            else if (col == "BookingType") {
                obj["Task Type"] = row[col];
            }
            else if (col == "ZoneName") {
                obj["Zone Name"] = row[col];
            }
            else if (col == "DistrictName") {
                obj["District Name"] = row[col];
            }
            else if (col == "MuncipalityName") {
                obj["Muncipality Name"] = row[col];
            }
            else if (col == "WardName") {
                obj["Ward Name"] = row[col];
            }
            else if (col == "Locality") {
                obj["Locality"] = row[col];
            }
            else if (col == "DrainageLine150to300mmDiaMeter") {
                obj["150 to 300mm DiaMeter"] = row[col];
            }
            else if (col == "DrainageLine301to600mmDiaMeter") {
                obj["301 to 600mm DiaMeter"] = row[col];
            }
            else if (col == "DrainageLine601to900mmDiaMeter") {
                obj["601 to 900mm DiaMeter"] = row[col];
            }
            else if (col == "DrainageLine901to1200mmDiaMeter") {
                obj["901 to 1200mm DiaMeter"] = row[col];
            }
            else if (col == "BookingDate") {
                obj["Task Date"] = row[col];
            }
            else if (col == "PaymentReceivedDate") {
                obj["Payment Date"] = row[col];
            }else if (col == "BillNo") {
                obj["Bill No"] = row[col];
            }else if (col == "PaymentAmount") {
                obj["Amount"] = row[col];
            }
            else {
                obj[col] = row[col];
            }
        });
        return obj;
    });

    const ws1 = XLSX.utils.json_to_sheet(compatibleData, { headertemp });
    let wb = XLSX.utils.book_new({ Sheets: { data: ws1 }, SheetNames: [""] });
    // XLSX.utils.sheet_add_aoa(ws1, header, { origin: 'A1' });
    XLSX.utils.book_append_sheet(wb, ws1, '');
    ws1.A1.s = {
        font: { sz: 14, bold: true, color: '#FF00FF' }
    };

    ws1['!cols'] = [
        { width: 6 },
        { width: 30 },
        { width: 30 },
        { width: 30 },
        { width: 30 },
        { width: 30 },
        { width: 30 },
        { width: 30 },
        { width: 30 },
        { width: 30 },
        { width: 30 },
        { width: 30 },
        { width: 30 },
        { width: 30 },
        { width: 30 },
        { width: 30 },
        { width: 30 },
        { width: 30 },
        { width: 30 },
        { width: 30 },
        { width: 30 },
        { width: 30 },
        { width: 30 },

    ];
    let cell = {
        font: { bold: true, color: { rgb: "f7f4ef" } },
        fill: { patternType: 'solid', fgColor: { rgb: "54153255" } },
        alignment: { wrapText: true, horizontal: 'center' },
        border: {
            left: { style: 'thin', color: { rgb: '000000' } },
            right: { style: 'thin', color: { rgb: '000000' } },
            top: { style: 'thin', color: { rgb: '000000' } },
            bottom: { style: 'thin', color: { rgb: '000000' } }
        }
    }
    ws1['A1'].s = cell;
    ws1['B1'].s = cell;
    ws1['C1'].s = cell;
    ws1['D1'].s = cell;
    ws1['E1'].s = cell;
    ws1['F1'].s = cell;
    ws1['G1'].s = cell;
    ws1['H1'].s = cell;
    ws1['I1'].s = cell;
    ws1['J1'].s = cell;
    ws1['K1'].s = cell;
    ws1['L1'].s = cell;
    ws1['M1'].s = cell;
    ws1['N1'].s = cell;
    ws1['O1'].s = cell;
    ws1['P1'].s = cell;
    ws1['Q1'].s = cell;
    ws1['R1'].s = cell;
    ws1['S1'].s = cell;
    ws1['T1'].s = cell;
    ws1['U1'].s = cell;
    ws1['V1'].s = cell;
    ws1['W1'].s = cell;



    var RowCont = {
        alignment: { wrapText: true, horizontal: 'center', vertical: 'top' },
        border: {
            left: { style: 'thin', color: { rgb: '000000' } },
            right: { style: 'thin', color: { rgb: '000000' } },
            top: { style: 'thin', color: { rgb: '000000' } },
            bottom: { style: 'thin', color: { rgb: '000000' } },
        }
    }
    data.map((row, i) => {
        var ins = i + 2;
        ws1['A' + ins].s = RowCont;
        ws1['B' + ins].s = RowCont;
        ws1['C' + ins].s = RowCont;
        ws1['D' + ins].s = RowCont;
        ws1['E' + ins].s = RowCont;
        ws1['F' + ins].s = RowCont;
        ws1['G' + ins].s = RowCont;
        ws1['H' + ins].s = RowCont;
        ws1['I' + ins].s = RowCont;
        ws1['J' + ins].s = RowCont;
        ws1['K' + ins].s = RowCont;
        ws1['L' + ins].s = RowCont;
        ws1['M' + ins].s = RowCont;
        ws1['N' + ins].s = RowCont;
        ws1['O' + ins].s = RowCont;
        ws1['P' + ins].s = RowCont;
        ws1['Q' + ins].s = RowCont;
        ws1['R' + ins].s = RowCont;
        ws1['S' + ins].s = RowCont;
        ws1['T' + ins].s = RowCont;
        ws1['U' + ins].s = RowCont;
        ws1['V' + ins].s = RowCont;
        ws1['W' + ins].s = RowCont;

        return true;
    });
    XLSX.utils.book_append_sheet(wb, ws1, "AdminTaskList");
    XLSX.writeFile(wb, "AdminTaskList_" + moment().format('DD-MM-YYYY_HH:mm') + ".xlsx");
}
const A4_PAPER_DIMENSIONS = {
    width: 210,
    height: 210,
};

const A4_PAPER_RATIO = A4_PAPER_DIMENSIONS.width / A4_PAPER_DIMENSIONS.height;

export const imageDimensionsOnA4 = () => {
    debugger;
    const isLandscapeImage = 210 >= 297;

    if (isLandscapeImage) {
        return {
            width: A4_PAPER_DIMENSIONS.width,
            height:
                A4_PAPER_DIMENSIONS.width / (610 / 97),
        };
    }

    const imageRatio = 600 / 297;
    if (imageRatio > A4_PAPER_RATIO) {
        const imageScaleFactor =
            (A4_PAPER_RATIO * 260) / 600; // for space all doc

        const scaledImageHeight = A4_PAPER_DIMENSIONS.height * imageScaleFactor;

        return {

            height: scaledImageHeight * imageRatio,
            // height: "290",
            width: scaledImageHeight * imageRatio,
        };
    }

    return {
        width: A4_PAPER_DIMENSIONS.height / (600 / 297),
        height: A4_PAPER_DIMENSIONS.height,

    };
};
class viewadmintask extends Component {
    constructor(props) {
        super(props);
        const { cookies } = props;
        this.state = {
            name: cookies.get('name') || 'Ben'
        };
        this.state = {
            activeItem: 'viewadmintask',
            data: [], zones: [], districts: [], municipalities: [], wards: [],
            ZoneID: [], DistrictID: [], MunicipalityID: [], WardID: [], vehicles: [],
            VehicleID: { 'label': '--Select Vehicle--', 'value': '--Select Vehicle--' },
            BookingNo: "", PrintStatus: false, CheckPrintStatus: false,
            status: [{ 'label': 'New', 'value': 'New' }, { 'label': 'Assigned', 'value': 'Assigned' },
            { 'label': 'Started', 'value': 'Started' }, { 'label': 'Finished', 'value': 'Finished' },
            { 'label': 'Completed', 'value': 'Completed' }, { 'label': 'Approved', 'value': 'Approved' },
            { 'label': 'Referred Back', 'value': 'Referred Back' }],
            StatusID: { 'label': '--Select Status--', 'value': '--Select Status--' },
            StartDate: new Date(), EndDate: new Date(),
            faicon: "fa fa-plus", isFilterVisible: false, iconFilter: "fa fa-plus",
            VehicleNo: "",
            BookingDate: "", PDFBookingNo: "", Shift: "", ZoneName: "", WardName: "", Locality: "", ParkingLocationName: "",
            DistanceFromParkingLocationToLocationOfWork: "", VehicleLeaveParkingTime: "", VehicleReachedLocationTime: "",
            VehicleReturnLocationTime: "", VehicleReachedParkingTime: "", VehicleLeaveParkingKiloMeter: "",
            VehicleReachedLocationKiloMeter: "", VehicleReturnLocationKiloMeter: "", VehicleReachedParkingKiloMeter: "",
            JettingStartMeterReading: "", SuctionPumpStartMeterReading: "", JettingFinishMeterReading: "", SuctionPumpFinishMeterReading: "",
            TotalHours: "", TotalMinutes: "", TotalTime: "", TotalKilometer: "", TotalJettingUsageTime: "", TotalSuctionPumpUsageTime: "",
            TotalUsageJettingHour: "", TotalUsageJettingMinutes: "", TotalSuctionPumpUsageHour: "", TotalSuctionPumpUsageMinutes: "",
            TotalNoOfDranageLine: "", TotalDrainageMeter: "", DrainageLine150to300mmDiaMeter: "", DrainageLine301to600mmDiaMeter: "",
            DrainageLine601to900mmDiaMeter: "", DrainageLine901to1200mmDiaMeter: "", JettingSystem: "", SuctionSystem: "",
            SuctionHose5Diameter3MeterLong: "", JettingHose32Diameter120MeterLong: "", JettingNozzleDeChockingNozzle: "",
            DifferentLocationOdometer: "", MultipleHelperName: "",
            DeSiltingNozzleFlatNozzle: "", BalanceNozzleBombNozzle: "", DriverName: "", SupervisorName: "", AssignTaskSupervisorName:"",
            PDFStatus: false,
            IsSafetyShoesChecked: false, IsHandGlovesChecked: false, IsHelmetChecked: false, IsMaskChecked: false,
            IsLightReflectiveJacketChecked: false, IsUniformChecked: false, IsIDCardChecked: false,

            LogSheetNo: "", LocationDetail: "", EngineerName: "", EngineerDesignation: "", MachineNotWorkFromTime: "",
            MachineNotWorkToTime: "",
            MachineNotWorkTotalTime: "", ExtraordinaryDetails: "", ExtraordinaryArea: "", ExtraordinaryMachineHoles: "",
            SludgeDisposalArea: "", IsSludgeDisposalAreaYes: false, IsSludgeDisposalAreaNo: false,

            ClientName: "", LunchStartTime: "", LunchEndTime: "", TotalLunchTime: "", MuncipalityName: "", VehicleLeaveParkingTime2: "", VehicleReachedLocationTime2: "", VehicleReturnLocationTime2: "", VehicleReachedParkingTime2: "",

            cols: [
                { title: 'Sr.No', width: '5%', field: 'tableData.id', headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px' }}>{rowData.tableData.id + 1}</p>) } },
                {
                    title: 'Details', width: '5%', removable: false, hidden: true, headerStyle: headerTblStyle, render: rowData => {
                        return (<i style={{ color: 'black' }} className={this.state.faicon} onClick={this.onToggleDetailPanel(rowData.BookingID, rowData.tableData.id)}></i>)
                    }
                },
                { title: 'Status', width: '5%', removable: false, headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px' }}>{rowData.BookingStatus}</p>) } },
                {
                    title: 'LogBook', width: '5%', removable: false, headerStyle: headerTblStyle, render: rowData => {
                        return (<p style={{ marginBottom: '1px' }}>{
                            (rowData.LogSheetStatus == "true") ? <a style={{ color: 'black', borderColor: 'white' }} className="btn btn-outline-danger" onClick={this.LogBookClick(rowData.BookingID)}>
                                <i className="fa fa-file-pdf" style={{ fontSize: 24, color: 'black' }} />Log Book</a>
                                // :(rowData.BookingStatus=="Approved")? <a style={{ color: 'black', borderColor: 'white' }} className="btn btn-outline-danger" onClick={this.LogBookClick(rowData.BookingID)}>
                                //     <i className="fa fa-file-pdf" style={{ fontSize: 24, color: 'black' }} />Log Book</a>
                                : ''
                        }</p>)
                    }
                },
                {
                    title: (<div style={{ width: '100%' }}>
                        <span>Country Name</span><hr style={{ marginTop: '5px', marginBottom: '5px' }} />
                        <span>State Name</span><hr style={{ marginTop: '5px', marginBottom: '5px' }} />
                        <span>City Name</span><hr style={{ marginTop: '5px', marginBottom: '5px' }} />
                        <span>Vehicle No</span></div>), width: '10%', removable: false, headerStyle: headerTblStyle,
                    render: rowData => {
                        return (
                            <p style={{ marginBottom: '1px' }}>{rowData.CountryName}<hr />
                                {rowData.StateName}<hr />
                                {rowData.CityName}<hr />
                                {(rowData.BookingTaskDetail) !== null ? (rowData.BookingTaskDetail[0].VehicleNo) : ''}</p>)
                    }
                },
                { title: (<div style={{ width: '100%' }}><span>Task No</span><hr style={{ marginTop: '5px', marginBottom: '5px' }} /><span>Task Type</span></div>), width: '10%', removable: false, headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px' }}>{rowData.BookingNo}<hr />{rowData.BookingType}</p>) } },
                { title: (<div style={{ width: '100%' }}><span>Zone Name</span><hr style={{ marginTop: '5px', marginBottom: '5px' }} /><span>District Name</span><hr style={{ marginTop: '5px', marginBottom: '5px' }} /><span>Municipality Name</span><hr style={{ marginTop: '5px', marginBottom: '5px' }} /><span>Ward Name</span></div>), width: '20%', removable: false, headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px' }}>{rowData.ZoneName}<hr />{rowData.DistrictName}<hr />{rowData.MuncipalityName}<hr />{rowData.WardName}</p>) } },
                { title: (<div style={{ width: '100%' }}><span>Locality</span><hr style={{ marginTop: '5px', marginBottom: '5px' }} /><span>Priority</span></div>), width: '10%', removable: false, headerStyle: headerTblStyle, render: rowData => { return (<><p style={{ marginBottom: '1px' }}>{rowData.Locality}</p><hr /><p className={rowData.Priority == "High" ? "label font-weight-bold label-lg label-light-danger label-inline" : rowData.Priority == "Medium" ? "label font-weight-bold label-lg label-light-primary label-inline" : "label font-weight-bold label-lg label-light-success label-inline"} style={{ marginBottom: '1px' }}>{rowData.Priority}</p></>) } },
                { title: (<div style={{ width: '100%' }}><span>150 to 300mm </span><hr style={{ marginTop: '5px', marginBottom: '5px' }} /><span>301 to 600mm </span><hr style={{ marginTop: '5px', marginBottom: '5px' }} /><span>601 to 900mm </span><hr style={{ marginTop: '5px', marginBottom: '5px' }} /><span>901 to 1200mm </span></div>), width: '10%', removable: false, headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px' }}>{rowData.DrainageLine150to300mmDiaMeter}<hr />{rowData.DrainageLine301to600mmDiaMeter}<hr />{rowData.DrainageLine601to900mmDiaMeter}<hr />{rowData.DrainageLine901to1200mmDiaMeter}</p>) } },
                // { title: 'Locality', width: '10%', removable: false, headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px' }}>{rowData.Locality}</p>) } },
                // { title: 'Priority', width: '10%', removable: false, headerStyle: headerTblStyle, render: rowData => { return (<p className={rowData.Priority=="High"? "label font-weight-bold label-lg label-light-danger label-inline":rowData.Priority=="Medium"? "label font-weight-bold label-lg label-light-primary label-inline":"label font-weight-bold label-lg label-light-success label-inline"} style={{ marginBottom: '1px' }}>{rowData.Priority}</p>) } },
                { title: 'Remarks', width: '10%', removable: false, headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px' }}>{rowData.Remarks}</p>) } },
                {
                    title: 'LogSheet Image', width: '5%', removable: false, headerStyle: headerTblStyle, render: rowData => {
                        return (<p style={{ marginBottom: '1px' }}>{
                            (rowData.LogSheetImage !== null) ?
                                (<a target="_blank" href={process.env.REACT_APP_BOOKINGIMAGE_URL + rowData.LogSheetImage}>
                                    <img className="img-thumbnail" target="_blank" href={process.env.REACT_APP_BOOKINGIMAGE_URL + rowData.LogSheetImage} style={{ height: '80px', width: '80px', marginRight: '3%', marginBottom: '3%' }} src={process.env.REACT_APP_BOOKINGIMAGE_URL + rowData.LogSheetImage}></img>
                                </a>) : ''}</p>)
                    }
                },
                { title: (<div style={{ width: '100%' }}><span>Task Date</span><hr style={{ marginTop: '5px', marginBottom: '5px' }} /><span>Reffer Back Assign Date</span></div>), width: '10%', removable: false, headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px' }}>{rowData.BookingDate}<hr />{(rowData.BookingTaskDetail !== null) ? rowData.BookingTaskDetail[0].RefferBackAssignDate : ''}</p>) } },
                // { title: 'Task Date', width: '10%', removable: false, headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px' }}>{rowData.BookingDate}</p>) } },
            ],
        }
    }
    componentDidMount() {
        this.GetZone();
        this.GetDistrict();
        this.GetMunicipality();
        this.GetWard();
        this.GetVehicle();
        this.GetAdminTask();
        //  this.GetBooking(10020);
    }
    GetVehicle() {
        var CompanyID = "";
        var UserID = "";
        if (this.props.allCookies.MainCompanyID !== undefined) {
            CompanyID = this.props.allCookies.MainCompanyID;
        }

        var data = "";
        data = JSON.stringify({
            "VehicleID": "",
            "CompanyID": CompanyID,
            "IsActive": "true",
            "IsDelete": "false"
        });

        var config = {
            method: 'POST',
            url: services.API_URL + "Vehicle/getVehicle",
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        services.JsonValue(config).then(response => {
            ////debugger;

            if (response.data.status) {
                this.setState({ vehicles: response.data.data.map(item => ({ value: item.VehicleID, label: item.VehicleNo })) });


            }
            else {
                this.setState({ vehicles: [] })
            }
        }, error => { })
    }
    GetBooking(id) {
        debugger;
        //   window.location.reload(true);
        var CompanyID = "";
        var ClientID = "";
        var UserID = "";
        var MunicipalityID = "";
        var UserType = "";
        if (this.props.allCookies.MainCompanyID !== undefined) {
            CompanyID = this.props.allCookies.MainCompanyID;
        }
        if (this.props.allCookies.MainClientID !== undefined) {
            ClientID = this.props.allCookies.MainClientID
        }

        var APIName = "";
        var data = "";

        APIName = "Report/getBookingLogSheetData"
        data = JSON.stringify({
            BookingID: id,
            CompanyID: CompanyID,
            ClientID: ClientID,
            UserID: UserID,
            UserTypeID: "",
            UserType: "",
            BookingTypeID: "",
            BookingNo: "",
            ZoneID: "",
            DistrictID: "",
            MuncipalityID: "",
            WardID: "",
            Priority: "",
            BookingStatus: "",
            StartDate: "",
            EndDate: ""
        });


        // console.log("data", data);
        var config = {
            method: 'POST',
            url: services.API_URL + APIName,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        services.JsonValue(config).then(response => {
            debugger;
            if (response.data.status) {
                this.setState({ BookingDate: response.data.data[0].BookingDate });
                this.setState({ VehicleNo: response.data.data[0].VehicleNo });
                this.setState({ PDFBookingNo: response.data.data[0].BookingNo });
                this.setState({ Shift: response.data.data[0].Shift });
                this.setState({ ZoneName: response.data.data[0].ZoneName });
                this.setState({ WardName: response.data.data[0].WardName });
                this.setState({ Locality: response.data.data[0].Locality });
                this.setState({ ParkingLocationName: response.data.data[0].ParkingLocationName });
                this.setState({ DistanceFromParkingLocationToLocationOfWork: response.data.data[0].DistanceFromParkingLocationToLocationOfWork });
                this.setState({ VehicleLeaveParkingTime: response.data.data[0].VehicleLeaveParkingTime });
                this.setState({ VehicleLeaveParkingTime2: response.data.data[0].VehicleLeaveParkingTime2 });
                this.setState({ VehicleReachedLocationTime: response.data.data[0].VehicleReachedLocationTime });
                this.setState({ VehicleReachedLocationTime2: response.data.data[0].VehicleReachedLocationTime2 });
                this.setState({ VehicleReturnLocationTime: response.data.data[0].VehicleReturnLocationTime });
                this.setState({ VehicleReturnLocationTime2: response.data.data[0].VehicleReturnLocationTime2 });
                this.setState({ VehicleReachedParkingTime: response.data.data[0].VehicleReachedParkingTime });
                this.setState({ VehicleReachedParkingTime2: response.data.data[0].VehicleReachedParkingTime2 });
                this.setState({ VehicleLeaveParkingKiloMeter: response.data.data[0].VehicleLeaveParkingOdometer });
                this.setState({ VehicleReachedLocationKiloMeter: response.data.data[0].VehicleReachedLocationOdometer });
                this.setState({ VehicleReturnLocationKiloMeter: response.data.data[0].VehicleReturnLocationOdometer });
                this.setState({ VehicleReachedParkingKiloMeter: response.data.data[0].VehicleReachedParkingOdometer });
                this.setState({ JettingStartMeterReading: response.data.data[0].JettingStartMeterReading });
                this.setState({ SuctionPumpStartMeterReading: response.data.data[0].SuctionPumpStartMeterReading });
                this.setState({ SuctionPumpFinishMeterReading: response.data.data[0].SuctionPumpFinishMeterReading });
                this.setState({ JettingFinishMeterReading: response.data.data[0].JettingFinishMeterReading });
                this.setState({ TotalTime: response.data.data[0].TotalTime });
                this.setState({ TotalKilometer: response.data.data[0].TotalKiloMeter });

                var VehicleLeaveParkingOdometer = response.data.data[0].VehicleLeaveParkingOdometer;
                var VehicleReachedLocationOdometer = response.data.data[0].VehicleReachedLocationOdometer;

                var TotalDifferenceOdometer = VehicleReachedLocationOdometer - VehicleLeaveParkingOdometer;
                var HelperName = "";
                this.setState({ DifferentLocationOdometer: TotalDifferenceOdometer });


                if (response.data.data[0].TotalTime != null) {
                    var TotalTime = response.data.data[0].TotalTime.split(':');
                    this.setState({ TotalHours: TotalTime[0] });
                    this.setState({ TotalMinutes: TotalTime[1] });
                }

                if (response.data.data[0].TotalJettingUsageTime != null) {
                    var TotalJettingUsageTime = response.data.data[0].TotalJettingUsageTime.split(':');
                    this.setState({ TotalUsageJettingHour: TotalJettingUsageTime[0] });
                    this.setState({ TotalUsageJettingMinutes: TotalJettingUsageTime[1] });
                }

                if (response.data.data[0].TotalSuctionPumpUsageTime != null) {
                    var TotalSuctionPumpUsageTime = response.data.data[0].TotalSuctionPumpUsageTime.split(':');
                    this.setState({ TotalSuctionPumpUsageHour: TotalSuctionPumpUsageTime[0] });
                    this.setState({ TotalSuctionPumpUsageMinutes: TotalSuctionPumpUsageTime[1] });
                }

                this.setState({ TotalNoOfDranageLine: response.data.data[0].TotalNoOfDranageLine });
                this.setState({ TotalDrainageMeter: response.data.data[0].TotalMeter });
                this.setState({ DrainageLine150to300mmDiaMeter: response.data.data[0].DrainageLine150to300mmDiaMeter });
                this.setState({ DrainageLine301to600mmDiaMeter: response.data.data[0].DrainageLine301to600mmDiaMeter });
                this.setState({ DrainageLine601to900mmDiaMeter: response.data.data[0].DrainageLine601to900mmDiaMeter });
                this.setState({ DrainageLine901to1200mmDiaMeter: response.data.data[0].DrainageLine901to1200mmDiaMeter });
                this.setState({ JettingSystem: response.data.data[0].JettingSystem });
                this.setState({ SuctionSystem: response.data.data[0].SuctionSystem });
                this.setState({ SuctionHose5Diameter3MeterLong: response.data.data[0].SuctionHose5Diameter3MeterLong });
                this.setState({ JettingHose32Diameter120MeterLong: response.data.data[0].JettingHose32Diameter120MeterLong });
                this.setState({ JettingNozzleDeChockingNozzle: response.data.data[0].JettingNozzleDeChockingNozzle });
                this.setState({ DeSiltingNozzleFlatNozzle: response.data.data[0].DeSiltingNozzleFlatNozzle });
                this.setState({ BalanceNozzleBombNozzle: response.data.data[0].BalanceNozzleBombNozzle });
                this.setState({ DriverName: response.data.data[0].DriverFullName });
                this.setState({ SupervisorName: response.data.data[0].AssignSupervisorFullName });
                this.setState({ AssignTaskSupervisorName: response.data.data[0].SupervisorFullName });
                var BookingTaskCheckListDetail = response.data.data[0].BookingTaskCheckListDetail;
                if (BookingTaskCheckListDetail != null) {
                    debugger;
                    for (let i = 0; i < response.data.data[0].BookingTaskCheckListDetail.length; i++) {
                        if (response.data.data[0].BookingTaskCheckListDetail[i].CheckListTitle == "Safety Shoes") {
                            this.setState({ IsSafetyShoesChecked: true });
                        }
                        if (response.data.data[0].BookingTaskCheckListDetail[i].CheckListTitle == "Hand Gloves") {
                            this.setState({ IsHandGlovesChecked: true });
                        }
                        if (response.data.data[0].BookingTaskCheckListDetail[i].CheckListTitle == "Helmet") {
                            this.setState({ IsHelmetChecked: true });
                        }
                        if (response.data.data[0].BookingTaskCheckListDetail[i].CheckListTitle == "Mask") {
                            this.setState({ IsMaskChecked: true });
                        }
                        if (response.data.data[0].BookingTaskCheckListDetail[i].CheckListTitle == "Light Reflective Jacket") {
                            this.setState({ IsLightReflectiveJacketChecked: true });
                        }
                        if (response.data.data[0].BookingTaskCheckListDetail[i].CheckListTitle == "Uniform") {
                            this.setState({ IsUniformChecked: true });
                        }
                        if (response.data.data[0].BookingTaskCheckListDetail[i].CheckListTitle == "I-Card") {
                            this.setState({ IsIDCardChecked: true });
                        }

                    }
                }

                var BookingTaskHelperDetail = response.data.data[0].BookingTaskHelperDetail;
                if (BookingTaskHelperDetail != null) {
                    for (let i = 0; i < response.data.data[0].BookingTaskHelperDetail.length; i++) {
                        HelperName += response.data.data[0].BookingTaskHelperDetail[i].FullName + ",";
                    }
                }
                HelperName = HelperName.slice(0, -1)
                if (HelperName != "") {
                    this.setState({ MultipleHelperName: HelperName });
                }

                this.setState({
                    LogSheetNo: response.data.data[0].LogSheetNo != null ? response.data.data[0].LogSheetNo : '',
                    LocationDetail: response.data.data[0].LocationDetail != null ? response.data.data[0].LocationDetail : '',
                    EngineerName: response.data.data[0].EngineerName != null ? response.data.data[0].EngineerName : '',
                    EngineerDesignation: response.data.data[0].EngineerDesignation != null ? response.data.data[0].EngineerDesignation : '',
                    ExtraordinaryDetails: response.data.data[0].ExtraordinaryDetails != null ? response.data.data[0].ExtraordinaryDetails : '',
                    ExtraordinaryArea: response.data.data[0].ExtraordinaryArea != null ? response.data.data[0].ExtraordinaryArea : '',
                    ExtraordinaryMachineHoles: response.data.data[0].ExtraordinaryMachineHoles != null ? response.data.data[0].ExtraordinaryMachineHoles : '',
                    MachineNotWorkFromTime: response.data.data[0].MachineNotWorkFromTime != null ? response.data.data[0].MachineNotWorkFromTime : '',
                    MachineNotWorkToTime: response.data.data[0].MachineNotWorkToTime != null ? response.data.data[0].MachineNotWorkToTime : '',
                    MachineNotWorkTotalTime: response.data.data[0].MachineNotWorkTotalTime != null ? response.data.data[0].MachineNotWorkTotalTime : ''
                });

                if (response.data.data[0].SludgeDisposalArea == "No") {
                    this.setState({ IsSludgeDisposalAreaNo: true })
                } else {
                    this.setState({ IsSludgeDisposalAreaYes: true })
                }

                this.setState({ ClientName: response.data.data[0].ClientName });
                this.setState({ MuncipalityName: response.data.data[0].MuncipalityName });
                this.setState({ LunchStartTime: (response.data.data[0].LunchStartTime) ? (response.data.data[0].LunchStartTime) : "" });
                this.setState({ LunchEndTime: (response.data.data[0].LunchEndTime) ? (response.data.data[0].LunchEndTime) : "" });
                this.setState({ TotalLunchTime: (response.data.data[0].TotalLunchTime) ? (response.data.data[0].TotalLunchTime) : "" });
                this.setState({ PDFStatus: true }, () => { this.generateImage() });


                // const img=document.getElementById('divToPrint');
                // htmlToImage.toJpeg(img, { quality: 1, backgroundColor: "#FFFFFF", height: img.clientHeight, width: img.clientWidth })
                //     .then(function (dataUrl) {
                //         debugger;
                //         const doc=new jsPDF();
                //         doc.deletePage(1);

                //         const imageDimensions=imageDimensionsOnA4({
                //             width: 300,
                //             height: 597,
                //         });

                //         doc.addPage();
                //         doc.addImage(
                //             dataUrl,
                //             //image.imageType,
                //             (A4_PAPER_DIMENSIONS.width-imageDimensions.width)/2,
                //             (A4_PAPER_DIMENSIONS.height-imageDimensions.height)/2,
                //             imageDimensions.width,
                //             imageDimensions.height
                //         );
                //         const pdfURL=doc.output("bloburl");
                //         //window.open(pdfURL, "_blank");
                //         let alink=document.createElement('a');
                //         alink.href=pdfURL;
                //         alink.download='Logsheet.pdf';
                //         alink.click();

                //     })
                //     .catch(function (error) {
                //         console.error('oops, something went wrong!', error);
                //     });

                // this.generateImage();
                //this.setState({ data: response.data.data })
            }
            else {
                // this.setState({ data: [] })
            }

        }, error => { })
    }

    generateImage = () => {
        debugger;
        var cname = this.state.ClientName;
        let img, id;
        // if (cname == "SMT Surat") {
        if (cname == "Gujarat Urban Development Mission") {
            img = document.getElementById('divToGUDMPrint');
            id = document.getElementById('divToGUDMPrint');
            id.style.display = 'block';
        } else {
            img = document.getElementById('divToPrint');
            id = document.getElementById('divToPrint');
            id.style.display = 'block';
        }
        htmlToImage.toJpeg(img, { quality: 1, backgroundColor: "#FFFFFF", height: img.clientHeight, width: img.clientWidth })
            .then(function (dataUrl) {
                debugger;
                //download(dataUrl,'image.jpg')
                const doc = new jsPDF();
                doc.deletePage(1);

                const imageDimensions = imageDimensionsOnA4({
                    width: 210,
                    height: 210,
                });

                doc.addPage();
                doc.addImage(
                    dataUrl,
                    //image.imageType,
                    (A4_PAPER_DIMENSIONS.width - imageDimensions.width) / 2,
                    (A4_PAPER_DIMENSIONS.height - imageDimensions.height) / 2,
                    //200,200
                    imageDimensions.width,
                    // imageDimensions.height
                    280
                );
                const pdfURL = doc.output("bloburl");
                //window.open(pdfURL, "_blank");
                id.style.display = 'none';
                let alink = document.createElement('a');
                alink.href = pdfURL;
                alink.download = "Logsheet_" + moment().format('DD-MM-YYYY_HH:mm') + ".pdf";
                alink.click();

            })
            .catch(function (error) {
                console.error('oops, something went wrong!', error);
            });
    }

    LogBookClick = (Bookingid) => (e) => {
        debugger;
        this.setState({});
        //this.setState({PDFBookingNo:2});
        this.setState({ CheckPrintStatus: true }, () => { this.GetBooking(Bookingid); });

        // this.generateImage();

    }
    //Functions
    GetAdminTask() {
        debugger;
        var CompanyID = "";
        var ClientID = "";
        var UserID = "";
        var ZoneID = "";
        var DistrictID = "";
        var MunicipalityID = "";
        var WardID = "";
        var Status = "";
        var VehicleID = "";
        var StartDate = "";
        var EndDate = "";
        if (this.props.allCookies.MainCompanyID !== undefined) {
            CompanyID = this.props.allCookies.MainCompanyID;
        }
        if (this.props.allCookies.MainClientID !== undefined) {
            ClientID = this.props.allCookies.MainClientID
        }


        if (this.state.ZoneID != null) {
            var tempZoneID = this.state.ZoneID.map(

                item => (
                    ZoneID += item + ","
                )
            );
            ZoneID = ZoneID.slice(0, -1)
        }
        if (this.state.DistrictID != null) {
            var tempDistrictID = this.state.DistrictID.map(

                item => (
                    DistrictID += item + ","
                )
            );
            DistrictID = DistrictID.slice(0, -1)
        }
        if (this.state.MunicipalityID != null) {
            var tempMunicipalityID = this.state.MunicipalityID.map(

                item => (
                    MunicipalityID += item + ","
                )
            );
            MunicipalityID = MunicipalityID.slice(0, -1)
        }
        if (this.state.WardID != null) {
            var tempWardID = this.state.WardID.map(

                item => (
                    WardID += item + ","
                )
            );
            WardID = WardID.slice(0, -1)
        }
        if (this.state.VehicleID.value != "--Select Vehicle--") {
            VehicleID = this.state.VehicleID.value;
        }
        if (this.state.StatusID.value != "--Select Status--") {
            Status = this.state.StatusID.value
        }

        var data = "";
        data = JSON.stringify({
            BookingID: "",
            CompanyID: CompanyID,
            ClientID: ClientID,
            UserID: "",
            UserTypeID: "",
            UserType: "",
            BookingTypeID: "",
            BookingNo: this.state.BookingNo,
            ZoneID: ZoneID,
            DistrictID: DistrictID,
            MuncipalityID: MunicipalityID,
            WardID: WardID,
            Priority: "",
            VehicleID: VehicleID,
            BookingStatus: Status,
            StartDate: moment(this.state.StartDate, "DD-MM-YYYY"),
            EndDate: moment(this.state.EndDate, "DD-MM-YYYY"),
            // StartDate: "16-03-2023",
            // EndDate: "16-03-2023",
        });


        var config = {
            method: 'POST',
            url: services.API_URL + "Booking/getAdminBooking",
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        services.JsonValue(config).then(response => {
            if (response.data.status) {
                this.setState({ data: response.data.data })
            }
            else {
                this.setState({ data: [] })
            }

        }, error => { })
    }
    GetZone() {
        var CompanyID = "";
        var ClientID = "";
        var UserID = "";
        if (this.props.allCookies.MainCompanyID !== undefined) {
            CompanyID = this.props.allCookies.MainCompanyID;
        }
        if (this.props.allCookies.MainClientID !== undefined) {
            ClientID = this.props.allCookies.MainClientID
        }

        var data = "";
        data = JSON.stringify({
            ZoneID: "",
            CompanyID: CompanyID,
            ClientID: ClientID
        });

        var config = {
            method: 'POST',
            url: services.API_URL + "Zone/getZoneDropDownData",
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        services.JsonValue(config).then(response => {
            //debugger;

            if (response.data.status) {
                this.setState({ zones: response.data.data.map(item => ({ value: item.ZoneID, label: item.ZoneName })) });
                this.setState({ ZoneID: [] })

            }
            else {
                this.setState({ zones: [] })
            }
        }, error => { })
    }
    GetDistrict() {
        var CompanyID = "";
        var ClientID = "";
        var UserID = "";
        if (this.props.allCookies.MainCompanyID !== undefined) {
            CompanyID = this.props.allCookies.MainCompanyID;
        }
        if (this.props.allCookies.MainClientID !== undefined) {
            ClientID = this.props.allCookies.MainClientID
        }
        if (this.props.allCookies.UserID !== undefined) {
            UserID = this.props.allCookies.UserID
        }
        var data = "";
        data = JSON.stringify({
            "DistrictID": "",
            "CompanyID": CompanyID,
            "ClientID": ClientID,
            "ZoneID": ""
        });

        var config = {
            method: 'POST',
            url: services.API_URL + "District/getDistrictDropDownData",
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        services.JsonValue(config).then(response => {
            //debugger;

            if (response.data.status) {
                this.setState({ districts: response.data.data.map(item => ({ value: item.DistrictID, label: item.DistrictName })) });
                this.setState({ DistrictID: [] })

            }
            else {
                this.setState({ districts: [] })
            }
        }, error => { })
    }
    GetMunicipality() {
        var CompanyID = "";
        var ClientID = "";
        var UserID = "";
        if (this.props.allCookies.MainCompanyID !== undefined) {
            CompanyID = this.props.allCookies.MainCompanyID;
        }
        if (this.props.allCookies.MainClientID !== undefined) {
            ClientID = this.props.allCookies.MainClientID
        }

        var data = "";
        data = JSON.stringify({
            "MuncipalityID": "",
            "CompanyID": CompanyID,
            "ClientID": ClientID,
            "DistrictID": "",
            "ZoneID": ""
        });

        var config = {
            method: 'POST',
            url: services.API_URL + "Muncipality/getMuncipalityDropDownData",
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        services.JsonValue(config).then(response => {
            //debugger;

            if (response.data.status) {
                this.setState({ municipalities: response.data.data.map(item => ({ value: item.MuncipalityID, label: item.MuncipalityName })) });
                this.setState({ MuncipalityID: [] })

            }
            else {
                this.setState({ municipalities: [] })
            }
        }, error => { })
    }
    GetWard() {
        var CompanyID = "";
        var ClientID = "";
        var UserID = "";
        if (this.props.allCookies.MainCompanyID !== undefined) {
            CompanyID = this.props.allCookies.MainCompanyID;
        }
        if (this.props.allCookies.MainClientID !== undefined) {
            ClientID = this.props.allCookies.MainClientID
        }

        var data = "";
        data = JSON.stringify({
            "WardID": "",
            "CompanyID": CompanyID,
            "ClientID": ClientID,
            "MuncipalityID": ""
        });

        var config = {
            method: 'POST',
            url: services.API_URL + "Ward/getWard",
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        services.JsonValue(config).then(response => {
            //debugger;

            if (response.data.status) {
                this.setState({ wards: response.data.data.map(item => ({ value: item.WardID, label: item.WardName })) });
                this.setState({ WardID: [] })

            }
            else {
                this.setState({ wards: [] })
            }
        }, error => { })
    }
    // Event Change
    onStartDateChange = (e) => {
        this.setState({ StartDate: e });
        this.setState({ EndDate: e });
    }
    onZoneChange = (value) => {
        //debugger;
        this.setState({ ZoneID: value });

    }
    onDistrictChange = (value) => {
        //debugger;
        this.setState({ DistrictID: value });

    }
    onMunicipalityChange = (value) => {
        //debugger;
        this.setState({ MunicipalityID: value });
    }
    onWardChange = (value) => {
        //debugger;
        this.setState({ WardID: value });
    }
    onStatusChange = (e) => {
        if (e !== null) {
            this.setState({ StatusID: e });
        }
        else {
            this.setState({ StatusID: { 'label': '--Select Status--', 'value': '--Select Status--' } });
        }
    }
    // Click Events
    OnFilterClick = (e) => {

        e.preventDefault();
        if (this.state.isFilterVisible === false) {
            this.setState({ isFilterVisible: true });
            this.setState({ iconFilter: "fa fa-minus" });
        }
        else {
            this.setState({ isFilterVisible: false });
            this.setState({ iconFilter: "fa fa-plus" });
        }

    }
    onVehicleChange = (e) => {
        if (e !== null) {
            this.setState({ VehicleID: e });
        }
        else {
            this.setState({ VehicleID: { 'label': '--Select Vehicle--', 'value': '--Select Vehicle--' } });
        }
    }
    onSearchTaskClick = (e) => {
        e.preventDefault();
        this.GetAdminTask();
    }
    OnSearchCancelTaskClick = (e) => {
        this.setState({
            zones: [], districts: [], municipalities: [], wards: [],
            ZoneID: [], DistrictID: [], MunicipalityID: [], WardID: [],
            BookingNo: "",
            status: [{ 'label': 'New', 'value': 'New' }, { 'label': 'Assigned', 'value': 'Assigned' }, { 'label': 'Started', 'value': 'Started' }, { 'label': 'Finished', 'value': 'Finished' }, { 'label': 'Completed', 'value': 'Completed' }, { 'label': 'Approved', 'value': 'Approved' }, { 'label': 'Referred Back', 'value': 'Referred Back' }],
            StatusID: { 'label': '--Select Status--', 'value': '--Select Status--' },
            VehicleID: { 'label': '--Select Vehicle--', 'value': '--Select Vehicle--' },
            StartDate: new Date(), EndDate: new Date()
        }, () => { this.GetAdminTask(); });
        this.GetZone();
        this.GetDistrict();
        this.GetMunicipality();
        this.GetWard();

    }
    ExcelClick = () => {
        exportToExcel(this.state.cols, this.state.data)
    }
    render() {
        return (
            <div className="container-fluid mobfluid" style={{ marginTop: '12%' }}>
                {this.state.isFilterVisible &&
                    <div className="row" style={{ marginBottom: '1%' }} id="divFilter">
                        <div className="col-md-3">
                            <div className="form-group formgroupcss">
                                <label>Start Date </label>
                                <DatePicker dateFormat="dd-MM-yyyy" selected={this.state.StartDate} autoComplete="off" className="form-control readonly" id="txtTaskDate" value={this.state.StartDate} onChange={this.onStartDateChange}
                                />
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="form-group formgroupcss">
                                <label>End Date </label>
                                <DatePicker dateFormat="dd-MM-yyyy" selected={this.state.EndDate} minDate={this.state.StartDate} autoComplete="off" className="form-control readonly" id="txtTaskDate" value={this.state.EndDate} onChange={(e) => {
                                    this.setState({ EndDate: e });
                                }} />
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="form-group formgroupcss">
                                <label>Task No </label>
                                <input type="text" value={this.state.BookingNo} onChange={(e) => this.setState({ BookingNo: e.target.value })} onKeyPress={this.allowOnlyNumbers} className="form-control" placeholder="Enter Task No" />
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="form-group formgroupcss">
                                <label>Zone </label>
                                <MultiSelect style={{ borderColor: 'none', borderWidth: 'noe', borderStyle: 'none' }}
                                    options={this.state.zones} selected={this.state.ZoneID} checked={false}
                                    onSelectedChanged={this.onZoneChange}
                                    overrideStrings={{
                                        selectSomeItems: "Zones", allItemsAreSelected: "All Zones are Selected",
                                        selectAll: "Select All", search: "Search"
                                    }} />
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="form-group formgroupcss">
                                <label>District </label>
                                <MultiSelect style={{ borderColor: 'none', borderWidth: 'noe', borderStyle: 'none' }}
                                    options={this.state.districts} selected={this.state.DistrictID} checked={false}
                                    onSelectedChanged={this.onDistrictChange}
                                    overrideStrings={{
                                        selectSomeItems: "Districts", allItemsAreSelected: "All Districts are Selected",
                                        selectAll: "Select All", search: "Search"
                                    }} />
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="form-group formgroupcss">
                                <label>Municipality </label>
                                <MultiSelect style={{ borderColor: 'none', borderWidth: 'noe', borderStyle: 'none' }}
                                    options={this.state.municipalities} selected={this.state.MunicipalityID} checked={false}
                                    onSelectedChanged={this.onMunicipalityChange}
                                    overrideStrings={{
                                        selectSomeItems: "Municipalities", allItemsAreSelected: "All Municipalities are Selected",
                                        selectAll: "Select All", search: "Search"
                                    }} />
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="form-group formgroupcss">
                                <label>Ward </label>
                                <MultiSelect style={{ borderColor: 'none', borderWidth: 'noe', borderStyle: 'none' }}
                                    options={this.state.wards} selected={this.state.WardID} checked={false}
                                    onSelectedChanged={this.onWardChange}
                                    overrideStrings={{
                                        selectSomeItems: "Wards", allItemsAreSelected: "All Wards are Selected",
                                        selectAll: "Select All", search: "Search"
                                    }} />
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="form-group formgroupcss">
                                <label>Vehicle </label>
                                <Select isClearable={true} options={this.state.vehicles} value={this.state.VehicleID} onChange={this.onVehicleChange} />
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="form-group formgroupcss">
                                <label>Status </label>
                                <Select isClearable={true} options={this.state.status} value={this.state.StatusID} onChange={this.onStatusChange} />
                            </div>
                        </div>
                        <div className="col-md-3">
                            <button type="button" onClick={this.onSearchTaskClick} className="btn btn-primary mt-12 mr-3 mobmt-1">Search </button>
                            <button type="button" onClick={this.OnSearchCancelTaskClick} className="btn btn-danger mt-12 mobmt-1">Cancel </button>
                        </div>
                    </div>
                }
                <div className="row">
                    <div className="col-md-12">
                        <div className="card card-custom">
                            <div className="card-body">
                                <MaterialTable columns={this.state.cols} data={this.state.data}
                                    detailPanel={[
                                        {
                                            icon: 'add', tooltip: 'Click here to see details', title: 'show',
                                            render: ({ rowData }) => {
                                                console.log(rowData,"rowDatarowData")
                                                if (rowData.BookingTaskDetail != null) {
                                                    return (
                                                        rowData.BookingTaskDetail.map((value) => {
                                                            return (
                                                                <div className="row" style={{ marginBottom: '2%' }}>
                                                                    <div style={{ border: '1px solid' }}>
                                                                        <div className="col-md-12" style={{ marginTop: '1%', marginBottom: '1%', textAlign: 'center' }}>
                                                                            <h3 className="tabletitle">Assign Task</h3>
                                                                        </div>
                                                                        <table className="table table-light-dark" align="center" cellSpacing="0" rules="all" border="1" style={{ width: '95%', borderCollapse: 'collapse' }}>
                                                                            <thead>
                                                                                <tr>
                                                                                    <td>Vehicle No</td>
                                                                                    <td>Supervisor</td>
                                                                                    <td>Driver</td>
                                                                                    <td>Helper</td>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                <tr>
                                                                                    <th>{value.VehicleNo}</th>
                                                                                    <th>{value.SupervisorFullName}</th>
                                                                                    <th>{value.DriverFullName}</th>
                                                                                    <th style={{ paddingTop: '1%', paddingBottom: '1%' }}>{
                                                                                        (value.BookingTaskHelperDetail != null) ?
                                                                                            (
                                                                                                value.BookingTaskHelperDetail.map((value) => {
                                                                                                    return (
                                                                                                        <span className="permissionborderdata">{value.FullName}</span>
                                                                                                    );
                                                                                                })
                                                                                            ) : ''
                                                                                    }</th>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                        <div className="col-md-12" style={{ marginTop: '1%', marginBottom: '1%', textAlign: 'center' }}>
                                                                            <h3 className="tabletitle">Start Task</h3>
                                                                        </div>
                                                                        <table className="table table-light-dark" align="center" cellSpacing="0" rules="all" border="1" style={{ width: '95%', borderCollapse: 'collapse' }}>
                                                                            <thead>
                                                                                <tr>
                                                                                    <td>Parking Location</td>
                                                                                    <td>Vehicle Leaving Parking Time</td>
                                                                                    <td>Vehicle Reaching Location Time</td>
                                                                                    <td>Odometer of Leaving Parking</td>
                                                                                    <td>Jetting Starting Meter Reading</td>
                                                                                    <td>Suction Pump Starting Meter Reading</td>
                                                                                    <td>Capture Details</td>
                                                                                    <td>CheckList</td>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                <tr>
                                                                                    <th>{value.ParkingLocationName}</th>
                                                                                    <th>{value.VehicleLeaveParkingTime}</th>
                                                                                    <th>{value.VehicleReachedLocationTime}</th>
                                                                                    <th>{value.VehicleLeaveParkingOdometer}</th>
                                                                                    <th>{value.JettingStartMeterReading}</th>
                                                                                    <th>{value.SuctionPumpStartMeterReading}</th>
                                                                                    <th>{
                                                                                        (value.BookingTaskImageDetail != null) ?
                                                                                            (
                                                                                                value.BookingTaskImageDetail.map((value) => {
                                                                                                    if (value.WorkImageType == "Start") {
                                                                                                        return (
                                                                                                            <a href={process.env.REACT_APP_BOOKINGIMAGE_URL + value.WorkImage} target="_blank">
                                                                                                                <img className="img-thumbnail" target="_blank" href={process.env.REACT_APP_BOOKINGIMAGE_URL + value.WorkImage} style={{ height: '80px', width: '80px', marginRight: '3%', marginBottom: '3%' }} src={process.env.REACT_APP_BOOKINGIMAGE_URL + value.WorkImage}></img>
                                                                                                            </a>
                                                                                                        );
                                                                                                    }

                                                                                                })
                                                                                            ) : '-'
                                                                                    }</th>
                                                                                    <th style={{ paddingTop: '1%', paddingBottom: '1%' }}>{
                                                                                        (value.BookingTaskCheckListDetail != null) ?
                                                                                            (
                                                                                                value.BookingTaskCheckListDetail.map((value) => {
                                                                                                    return (
                                                                                                        <span className="permissionborderdata">{value.CheckListTitle}</span>
                                                                                                    );
                                                                                                })
                                                                                            ) : ''
                                                                                    }</th>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                        <div className="col-md-12" style={{ marginTop: '1%', marginBottom: '1%', textAlign: 'center' }}>
                                                                            <h3 className="tabletitle">Finish Task</h3>
                                                                        </div>
                                                                        <table className="table table-light-dark" align="center" cellSpacing="0" rules="all" border="1" style={{ width: '95%', borderCollapse: 'collapse' }}>
                                                                            <thead>
                                                                                <tr>
                                                                                    <td>Odometer of Vehicle Reaching Work Location<hr></hr>
                                                                                        Odometer of Vehicle Leaving Work Location</td>
                                                                                    {/* Not */}
                                                                                    <td>Vehicle Leaving Work Location Time<hr></hr>
                                                                                        Vehicle Reaching Parking Location Time</td>
                                                                                    <td>Odometer Reaching Parking Location</td>
                                                                                    <td>Jetting Finish Meter Reading<hr></hr>
                                                                                        Suction Pump Finish Meter Reading</td>
                                                                                    <td>Lunch Start Time<hr></hr>
                                                                                        Lunch End Time</td>
                                                                                    <td>Total number of drainage line<hr></hr>Task Finish Remarks</td>
                                                                                    <td>150 mm to 300 mm dia meter<hr></hr>
                                                                                        301 mm to 600 mm dia meter</td>
                                                                                    <td>601 mm to 900 mm dia meter<hr></hr>
                                                                                        901 mm to 1200 mm dia meter</td>
                                                                                    <td>Jetting system<hr></hr>
                                                                                        Suction system</td>
                                                                                    <td>Suction hose 5 diameter - 3 meter long</td>
                                                                                    <td>Jetting hose - 32 m.m. dia, 120 m. long</td>
                                                                                    <td>Jetting nozzle: De Chocking nozzle</td>
                                                                                    <td>De Silting nozzle - flat nozzle</td>
                                                                                    <td>Balance nozzle bomb nozzle</td>
                                                                                    <td>Shift</td>
                                                                                    <td>Images</td>
                                                                                    {/* <td>Upload Multiple Images</td> */}
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                <tr>
                                                                                    <th>{value.VehicleReachedLocationOdometer}<hr></hr>{value.VehicleReturnLocationOdometer}</th>
                                                                                    <th>{value.VehicleReturnLocationTime}<hr></hr>{value.VehicleReachedParkingTime}</th>
                                                                                    <th>{value.VehicleReachedParkingOdometer}</th>
                                                                                    <th>{value.JettingFinishMeterReading}<hr></hr>{value.SuctionPumpFinishMeterReading}</th>
                                                                                    <th>{value.LunchStartTime}<hr></hr>{value.LunchEndTime}</th>
                                                                                    <th>{value.TotalNoOfDranageLine}<hr></hr>{value.BookingFinishRemarks}</th>
                                                                                    <th>{value.DrainageLine150to300mmDiaMeter}<hr></hr>{value.DrainageLine301to600mmDiaMeter}</th>
                                                                                    <th>{value.DrainageLine601to900mmDiaMeter}<hr></hr>{value.DrainageLine901to1200mmDiaMeter}</th>
                                                                                    <th>{value.JettingSystem}<hr></hr>{value.SuctionSystem}</th>
                                                                                    <th>{value.SuctionHose5Diameter3MeterLong}</th>
                                                                                    <th>{value.JettingHose32Diameter120MeterLong}</th>
                                                                                    <th>{value.JettingNozzleDeChockingNozzle}</th>
                                                                                    <th>{value.DeSiltingNozzleFlatNozzle}</th>
                                                                                    <th>{value.BalanceNozzleBombNozzle}</th>
                                                                                    <th>{value.Shift}</th>
                                                                                    <th>{
                                                                                        (value.BookingTaskImageDetail != null) ?
                                                                                            (
                                                                                                value.BookingTaskImageDetail.map((value) => {
                                                                                                    if (value.WorkImageType == "Finish") {
                                                                                                        return (
                                                                                                            <a href={process.env.REACT_APP_BOOKINGIMAGE_URL + value.WorkImage} target="_blank">
                                                                                                                <img className="img-thumbnail" target="_blank" href={process.env.REACT_APP_BOOKINGIMAGE_URL + value.WorkImage} style={{ height: '80px', width: '80px', marginRight: '3%', marginBottom: '3%' }} src={process.env.REACT_APP_BOOKINGIMAGE_URL + value.WorkImage}></img>
                                                                                                            </a>
                                                                                                        );
                                                                                                    }

                                                                                                })
                                                                                            ) : '-'
                                                                                    }</th>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                        <div className="col-md-12" style={{ marginTop: '1%', marginBottom: '1%', textAlign: 'center' }}>
                                                                            <h3 className="tabletitle">Complete Task</h3>
                                                                        </div>
                                                                        <table className="table table-light-dark" align="center" cellSpacing="0" rules="all" border="1" style={{ width: '95%', borderCollapse: 'collapse' }}>
                                                                            <thead>
                                                                                <tr>

                                                                                    <th>Remarks<hr></hr>Remarks in Gujarati</th>
                                                                                    <th>Tender liquidity damages condition</th>
                                                                                    <th>Log Sheet No</th>
                                                                                    <th>Location Detail</th>
                                                                                    <th>Engineer Name<hr></hr>Designation</th>
                                                                                    <th>Machine did not work From Time<hr></hr>Machine did not work To Time<hr></hr>Machine did not work Total Time</th>
                                                                                    <th>Extraordinary Details</th>
                                                                                    <th>Extraordinary Area</th>
                                                                                    <th>Machine Holes<hr></hr>Sludge Disposal Area Allocated By Municipality</th>
                                                                                    <th>Log Sheet Image</th>
                                                                                    {/* <td>Upload Multiple Images</td> */}
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td>{value.BookingCompleteRemarks}<hr></hr>{value.BookingCompleteRemarksGujarati}</td>
                                                                                    <td>{value.TenderLiquidityDamagesCondition}</td>
                                                                                    <td>{value.LogSheetNo}</td>
                                                                                    <td>{value.LocationDetail}</td>
                                                                                    <td>{value.EngineerName}<hr></hr>{value.EngineerDesignation}</td>
                                                                                    <td>{value.MachineNotWorkFromTime}<hr></hr>{value.MachineNotWorkToTime}<hr></hr>{value.MachineNotWorkTotalTime}</td>
                                                                                    <td>{value.ExtraordinaryDetails}</td>
                                                                                    <td>{value.ExtraordinaryArea}</td>
                                                                                    <td>{value.ExtraordinaryMachineHoles}<hr></hr>{value.SludgeDisposalArea}</td>
                                                                                    <td>{
                                                                                        (value.LogSheetImage != null) ? (
                                                                                            <a href={process.env.REACT_APP_BOOKINGIMAGE_URL + value.LogSheetImage} target="_blank">
                                                                                                <img className="img-thumbnail" target="_blank" href={process.env.REACT_APP_BOOKINGIMAGE_URL + value.LogSheetImage} style={{ height: '80px', width: '80px', marginRight: '3%', marginBottom: '3%' }} src={process.env.REACT_APP_BOOKINGIMAGE_URL + value.LogSheetImage}></img>
                                                                                            </a>

                                                                                        ) : ''
                                                                                    }</td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                        <div className="col-md-12" style={{ marginTop: '1%', marginBottom: '1%', textAlign: 'center' }}>
                                                                            <h3 className="tabletitle">Approval Task</h3>
                                                                        </div>
                                                                        <table className="table table-light-dark" align="center" cellSpacing="0" rules="all" border="1" style={{ width: '95%', borderCollapse: 'collapse' }}>
                                                                            <thead>
                                                                                <tr>
                                                                                    <td>Status</td>
                                                                                    <td>Remarks</td>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                <tr>
                                                                                    <th>{value.BookingApprovalStatus ? value.BookingApprovalStatus : '-'}</th>
                                                                                    <th>{value.BookingApprovalRemarks ? value.BookingApprovalRemarks : '-'}</th>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                        <div className="col-md-12" style={{ marginTop: '1%', marginBottom: '1%', textAlign: 'center' }}>
                                                                            <h3 className="tabletitle">Payment Details</h3>
                                                                        </div>
                                                                        <table className="table table-light-dark" align="center" cellSpacing="0" rules="all" border="1" style={{ width: '95%', borderCollapse: 'collapse' }}>
                                                                            <thead>
                                                                                <tr>
                                                                                    <td>Date</td>
                                                                                    <td>Bill No</td>
                                                                                    <td>Amount</td>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                <tr>
                                                                                    <th>{rowData.PaymentReceivedDate ? rowData.PaymentReceivedDate : '-'}</th>
                                                                                    <th>{rowData.BillNo ? rowData.BillNo : '-'}</th>
                                                                                    <th>{rowData.PaymentAmount ? rowData.PaymentAmount : '-'}</th>

                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })


                                                    )
                                                } else {
                                                    return false;
                                                }
                                            }
                                        },
                                        {
                                            icon: 'add', tooltip: 'Click here to see defect details', title: 'show',
                                            render: ({ rowData }) => {
                                                return (
                                                    <>
                                                        <div className="col-md-12" style={{ marginTop: '1%', marginBottom: '1%', textAlign: 'center' }}>
                                                            <h3 className="tabletitle">Defect Details</h3>
                                                        </div>
                                                        <table className="table table-light-dark" align="center" cellSpacing="0" rules="all" border="1" style={{ width: '95%', borderCollapse: 'collapse' }}>
                                                            <thead>
                                                                <tr>
                                                                    <td style={{ width: '10%' }}>Defect Name</td>
                                                                    <td style={{ width: '20%' }}>Checklist Details</td>
                                                                    <td style={{ width: '30%' }}>Images</td>
                                                                    <td style={{ width: '20%' }}>Latitude <hr style={{ marginTop: '5px', marginBottom: '5px' }} />Longitude<hr style={{ marginTop: '5px', marginBottom: '5px' }} />Address</td>
                                                                    <td style={{ width: '20%' }}>Remarks</td>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    (rowData.BookingDefectDetail != null) ?
                                                                        (
                                                                            rowData.BookingDefectDetail.map((value) => {
                                                                                return (
                                                                                    <tr className="mapfonts">
                                                                                        <td>
                                                                                            <span>{value.DefectName}</span>
                                                                                            {value.DefectDataEntryDateTime &&
                                                                                                <>
                                                                                                    <hr></hr>
                                                                                                    <span>{value.DefectDataEntryDateTime}</span>
                                                                                                </>
                                                                                            }
                                                                                        </td>
                                                                                        <td>
                                                                                            {
                                                                                                (value.BookingDefectCheckListDetail != null) ?
                                                                                                    (
                                                                                                        value.BookingDefectCheckListDetail.map((value) => {
                                                                                                            return (
                                                                                                                <span className="permissionborderdata">{value.CheckListTitle}</span>
                                                                                                            );
                                                                                                        })
                                                                                                    ) : ''
                                                                                            }
                                                                                        </td>
                                                                                        <td>
                                                                                            <div className="row">
                                                                                                {
                                                                                                    (value.BookingDefectImageDetail != null) ?
                                                                                                        (
                                                                                                            value.BookingDefectImageDetail.map((value) => {
                                                                                                                return (
                                                                                                                    <div className="col-md-2">
                                                                                                                        <a href={process.env.REACT_APP_BOOKINGIMAGE_URL + value.DefectImage} target="_blank">
                                                                                                                            <img className="img-thumbnail" target="_blank" href={process.env.REACT_APP_BOOKINGIMAGE_URL + value.DefectImage} style={{ height: '80px', width: '80px', marginRight: '3%', marginBottom: '3%', objectFit: 'contain' }} src={process.env.REACT_APP_BOOKINGIMAGE_URL + value.DefectImage}></img>
                                                                                                                        </a>
                                                                                                                    </div>
                                                                                                                );
                                                                                                            })
                                                                                                        ) : ''
                                                                                                }
                                                                                            </div>
                                                                                        </td>
                                                                                        <td>
                                                                                            <span>{value.Latitude}</span><hr />
                                                                                            <span>{value.Longitude}</span><hr />
                                                                                            <span>{value.Address}</span>
                                                                                        </td>
                                                                                        <td>
                                                                                            <span>{value.Remarks}</span>
                                                                                        </td>
                                                                                    </tr>
                                                                                );
                                                                            })) : <tr><td className="mapfonts" colSpan={7}><span>No Data Available</span></td>
                                                                        </tr>}
                                                            </tbody>
                                                        </table>
                                                    </>
                                                )
                                            }
                                        }
                                    ]}
                                    options={{
                                        headerStyle: { color: 'black' }, toolbar: true, search: false, draggable: false,
                                        paging: true, pageSize: 5, emptyRowsWhenPaging: true, pageSizeOptions: [5, 10, 15, 20],
                                        detailPanelType: "single",
                                        rowStyle: rowData => {
                                            if (rowData.IsRefferBack == "True") {
                                                return { backgroundColor: '#F64E60' };
                                            }
                                            return {};
                                        }
                                    }}
                                    components={{

                                        Toolbar: props => (
                                            <div className="row" style={{ marginBottom: '2%' }}>
                                                <div className="col-md-8 mobpb-5" style={{ display: 'flex', alignItems: 'center' }}>
                                                    <h3 className="tabletitle">View Administrator Task</h3>

                                                </div>
                                                <div className="col-md-4 mobta-left" style={{ textAlign: 'right' }}>
                                                    <a className="btn btn-outline-dark font-weight-bolder" onClick={this.OnFilterClick}>
                                                        <i id="btnFilter" className={this.state.iconFilter} /> Filter
                                                    </a>

                                                    <a className="btn btn-outline-success font-weight-bolder ml-4" onClick={this.state.data.length > 0 ? this.ExcelClick : ''}>
                                                        <i id="btnExcel" className="fa fa-file-excel" /> Excel
                                                    </a>

                                                </div>
                                            </div>
                                        )
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div id="divToPrint" className="divToPrint" style={{ display: 'none' }}>

                    <div className="row">
                        <div className="col-md-1" >
                            <img src="/Images/Amclogo.png" style={{ height: '80px', textAlign: 'left' }}></img>
                        </div>
                        <div className="col-md-6" style={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
                            <h3 style={{ width: '60%', fontWeight: 'bold', fontSize: '30px' }}>અમદાવાદ મ્યુનિસિપલ કોર્પોરેશન પર્ફોમન્સ સર્ટીફીકેટ</h3>
                        </div>
                        <div className="col-md-2" style={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                            <span style={{ fontWeight: 'bold', fontSize: '20px' }}>No.</span>
                            <span style={{ fontSize: '25px', borderBottom: '2px dotted', width: '100%' }}>{this.state.PDFBookingNo}</span>
                        </div>
                        <div className="col-md-3">
                            <img src="/Images/azadi.png" style={{ height: '80px' }}></img>
                        </div>
                        <div className="col-md-12" style={{ marginTop: '1%', textAlign: 'left' }}>
                            <span style={{ fontWeight: '500', fontSize: '25px' }}>આથી મે. મેટ્રો વેસ્ટ હેન્ડલીંગ પ્રા. લી.ને સર્ટીફીકેટ આપવામાં આવે છે કે, ડીશીલ્ટીંગ મશીન રજીસ્‍ટ્રેશન નંબર <span style={{ borderBottom: '2px dotted' }}>{this.state.VehicleNo}</span> દ્રારા તા <span style={{ borderBottom: '2px dotted' }}>{this.state.BookingDate}</span> </span>
                            <span style={{ fontWeight: '500', fontSize: '25px' }}>ના રોજ <span style={{ fontWeight: '500', fontSize: '25px', borderBottom: '2px dotted' }}>{this.state.Shift}</span> શીફ્ટમાં <span style={{ fontSize: '25px', borderBottom: '2px dotted', width: '100%' }}>{this.state.TotalTime}</span> કલાક માટે નીચે મુજબની વિગતથી કામગીરી કરેલ છે.</span>
                        </div>
                        {/* <div className="col-md-12" >
<span style={{ fontWeight: 'normal', fontSize: '25px' }}>આથી મે. મેટ્રો વેસ્ટ હેન્ડલીંગ પ્રા. લી.ને સર્ટીફીકેટ આપવામાં આવે છે કે, ડીશીલ્ટીંગ મશીન રજીસ્‍ટ્રેશન નંબર GJ-01-J-4782 દ્રારા તા.  ..............</span>

</div> */}

                        <div className="col-md-12" style={{ marginTop: '1%', textAlign: 'left' }}>
                            <span style={{ fontWeight: '500', fontSize: '25px' }}><span style={{ paddingRight: '5%' }}>૧.</span> ઝોનઃ <span style={{ fontWeight: '500', fontSize: '25px', borderBottom: '2px dotted' }}>{this.state.ZoneName}</span> વોર્ડ : <span style={{ fontWeight: '500', fontSize: '25px', borderBottom: '2px dotted' }}>{this.state.WardName}</span> કામગીરીનું સ્થળ: <span style={{ fontWeight: '500', fontSize: '25px', borderBottom: '2px dotted' }}>{this.state.Locality}</span></span>
                        </div>
                        <div className="col-md-12" style={{ marginTop: '1%', textAlign: 'left' }}>
                            <span style={{ fontWeight: '500', fontSize: '25px' }}><span style={{ paddingRight: '5%' }}>ર.</span> પાર્કિંગનું સ્થળ: <span style={{ fontWeight: '500', fontSize: '25px', borderBottom: '2px dotted' }}>{this.state.ParkingLocationName}</span> પાર્કિગના સ્થળ અને કામગીરીના સ્થળ વચ્ચેનું અંતર <span style={{ fontWeight: '500', fontSize: '25px', borderBottom: '2px dotted' }}>{this.state.DifferentLocationOdometer}</span></span>
                        </div>
                        <div className="col-md-12" style={{ marginTop: '1%', textAlign: 'left' }}>
                            <span style={{ fontSize: '25px' }}><span style={{ paddingRight: '5%' }}>૩.</span> કામગીરીની વિગત : <span style={{ fontWeight: '500', fontSize: '25px', borderBottom: '2px dotted' }}>{this.state.Locality}</span></span>
                        </div>
                        <div className="col-md-12" style={{ marginTop: '1%' }}>
                            <table style={{ border: '1px solid' }} className="table table-bordered">
                                <tr>
                                    <td style={{ border: '1px solid black', width: '10%' }}><span style={{ fontSize: '25px' }}>અનુ ક્રમાંક</span></td>
                                    <td style={{ border: '1px solid black', width: '20%' }}><span style={{ fontSize: '25px' }}>વિગત</span></td>
                                    <td style={{ border: '1px solid black', width: '13.75%' }}><span style={{ fontSize: '25px' }}>સમય</span></td>
                                    <td style={{ border: '1px solid black', width: '13.75%' }}><span style={{ fontSize: '25px' }}>કિલોમીટર</span></td>
                                    <td style={{ border: '1px solid black', width: '20%' }}><span style={{ fontSize: '25px' }}>વિગત</span></td>
                                    <td style={{ border: '1px solid black', width: '13.75%' }}><span style={{ fontSize: '25px' }}>સમય</span></td>
                                    <td style={{ border: '1px solid black', width: '13.75%' }}><span style={{ fontSize: '25px' }}>કિલોમીટર</span></td>
                                </tr>
                                <tr>
                                    <td style={{ border: '1px solid black', width: '5%' }}><span style={{ fontSize: '25px' }}>૧</span></td>
                                    <td style={{ border: '1px solid black', width: '20%', textAlign: 'left' }}><span style={{ fontSize: '25px' }}>મશીન પાર્કિંગ થી નિકળ્યાનો સમય</span></td>
                                    <td style={{ border: '1px solid black', width: '13.75%' }}><span style={{ fontWeight: '500', fontSize: '25px' }}>{this.state.VehicleLeaveParkingTime}</span></td>
                                    <td style={{ border: '1px solid black', width: '13.75%' }}><span style={{ fontWeight: '500', fontSize: '25px' }}>{this.state.VehicleLeaveParkingKiloMeter}</span></td>
                                    <td style={{ border: '1px solid black', width: '20%', textAlign: 'left' }}><span style={{ fontSize: '25px' }}>મશીન કામગીરીના સ્થળ ઉપર આવ્યાનો સમય</span></td>
                                    <td style={{ border: '1px solid black', width: '13.75%' }}><span style={{ fontWeight: '500', fontSize: '25px' }}>{this.state.VehicleReachedLocationTime}</span></td>
                                    <td style={{ border: '1px solid black', width: '13.75%' }}><span style={{ fontWeight: '500', fontSize: '25px' }}>{this.state.VehicleReachedLocationKiloMeter}</span></td>
                                </tr>
                                <tr>
                                    <td style={{ border: '1px solid black', width: '10%' }}><span style={{ fontSize: '25px' }}>ર</span></td>
                                    <td style={{ border: '1px solid black', width: '20%', textAlign: 'left' }}><span style={{ fontSize: '25px' }}>મશીન કામગીરીના સ્થળ ઉપરથી નિકળ્યાનો સમય</span></td>
                                    <td style={{ border: '1px solid black', width: '13.75%' }}><span style={{ fontWeight: '500', fontSize: '25px' }}>{this.state.VehicleReturnLocationTime}</span></td>
                                    <td style={{ border: '1px solid black', width: '13.75%' }}><span style={{ fontWeight: '500', fontSize: '25px' }}>{this.state.VehicleReturnLocationKiloMeter}</span></td>
                                    <td style={{ border: '1px solid black', width: '20%', textAlign: 'left' }}><span style={{ fontSize: '25px' }}>મશીન પાર્કિંગ ઉપર પહોચ્યાંનો સમય</span></td>
                                    <td style={{ border: '1px solid black', width: '13.75%' }}><span style={{ fontWeight: '500', fontSize: '25px' }}>{this.state.VehicleReachedParkingTime}</span></td>
                                    <td style={{ border: '1px solid black', width: '13.75%' }}><span style={{ fontWeight: '500', fontSize: '25px' }}>{this.state.VehicleReachedParkingKiloMeter}</span></td>
                                </tr>
                                <tr>
                                    <td style={{ border: '1px solid black', width: '10%' }}><span style={{ fontSize: '25px' }}>૩</span></td>
                                    <td style={{ border: '1px solid black', width: '20%', textAlign: 'left' }}><span style={{ fontSize: '25px' }}>કુલ સમય</span></td>
                                    <td style={{ border: '1px solid black', width: '13.75%', textAlign: 'left' }} colspan='2'><span style={{ fontSize: '25px' }}>કલાક </span><span style={{ fontWeight: '500', fontSize: '25px' }}>{this.state.TotalHours}</span> <span style={{ fontSize: '25px', paddingLeft: '120px' }}>મીનીટ </span><span style={{ fontWeight: '500', fontSize: '25px' }}>{this.state.TotalMinutes}</span> </td>
                                    {/* <td style={{ border: '1px solid black', width: '13.75%' }}><span style={{ fontSize: '25px' }}>મીનીટ</span></td> */}
                                    <td style={{ border: '1px solid black', width: '20%', textAlign: 'left' }}><span style={{ fontSize: '25px' }}>કુલ કિલોમીટર રીડીગ  </span></td>
                                    <td style={{ border: '1px solid black', width: '13.75%' }} colspan='2'><span style={{ fontWeight: '500', fontSize: '25px' }}>{this.state.TotalKilometer}</span></td>
                                    {/* <td style={{ border: '1px solid black', width: '15%' }}><span style={{ fontSize: '25px' }}></span></td> */}
                                </tr>
                                <tr>
                                    <td style={{ border: '1px solid black', width: '10%' }}><span style={{ fontSize: '25px' }}>૪</span></td>
                                    <td style={{ border: '1px solid black', textAlign: 'center' }} colspan='2'><span style={{ fontSize: '25px' }}>વિગત</span></td>
                                    <td style={{ border: '1px solid black', textAlign: 'center' }}><span style={{ fontSize: '25px' }}>રનીગ અવર્સના રીડીગ </span></td>
                                    <td style={{ border: '1px solid black', textAlign: 'center' }} colspan='2'><span style={{ fontSize: '25px' }}>વિગત</span></td>
                                    <td style={{ border: '1px solid black', textAlign: 'center' }}><span style={{ fontSize: '25px' }}>રનીગ અવર્સના રીડીગ </span></td>
                                </tr>
                                <tr>
                                    <td style={{ border: '1px solid black', width: '10%' }}><span style={{ fontSize: '25px' }}>પ</span></td>
                                    <td style={{ border: '1px solid black', textAlign: 'left' }} colspan='2'><span style={{ fontSize: '25px' }}>મશીનની જેટિગની કામગીરી શરુ કર્યા ના રનીગ અવર્સના રીડીગ</span></td>
                                    <td style={{ border: '1px solid black' }}><span style={{ fontWeight: '500', fontSize: '25px' }}>{this.state.JettingStartMeterReading}</span></td>
                                    <td style={{ border: '1px solid black', textAlign: 'left' }} colspan='2'><span style={{ fontSize: '25px' }}>મશીનની જેટિગની કામગીરી પૂર્ણ કર્યા ના રનીગ અવર્સના રીડીગ</span></td>
                                    <td style={{ border: '1px solid black' }}><span style={{ fontWeight: '500', fontSize: '25px' }}>{this.state.JettingFinishMeterReading}</span></td>
                                </tr>
                                <tr>
                                    <td style={{ border: '1px solid black', width: '10%' }}><span style={{ fontSize: '25px' }}>૬</span></td>
                                    <td style={{ border: '1px solid black', textAlign: 'left' }} colspan='2'><span style={{ fontSize: '25px' }}>મશીનની સકશનની કામગીરી શરુ કર્યા ના રનીગ અવર્સના રીડીગ</span></td>
                                    <td style={{ border: '1px solid black' }}><span style={{ fontWeight: '500', fontSize: '25px' }}>{this.state.SuctionPumpStartMeterReading}</span></td>
                                    <td style={{ border: '1px solid black', textAlign: 'left' }} colspan='2'><span style={{ fontSize: '25px' }}>મશીનની સકશનની કામગીરી પૂર્ણ કર્યા ના રનીગ અવર્સના રીડીગ</span></td>
                                    <td style={{ border: '1px solid black' }}><span style={{ fontWeight: '500', fontSize: '25px' }}>{this.state.SuctionPumpFinishMeterReading}</span></td>
                                </tr>
                                <tr>
                                    <td style={{ border: '1px solid black', width: '10%' }}><span style={{ fontSize: '25px' }}>૭</span></td>
                                    <td style={{ border: '1px solid black', textAlign: 'justify' }} colspan='3'><span style={{ fontSize: '25px' }}>કુલ જેટિગ સમય:</span><span style={{ fontSize: '25px', paddingLeft: '120px' }}>કલાક </span> <span style={{ fontWeight: '500', fontSize: '25px' }}>{this.state.TotalUsageJettingHour}</span> <span style={{ fontSize: '25px', paddingLeft: '120px' }}>મીનીટ </span> <span style={{ fontWeight: '500', fontSize: '25px' }}>{this.state.TotalUsageJettingMinutes}</span> </td>
                                    <td style={{ border: '1px solid black', textAlign: 'left' }} colspan='3'><span style={{ fontSize: '25px' }}>કુલ સકશન સમય:</span><span style={{ fontSize: '25px', paddingLeft: '60px' }}>કલાક </span> <span style={{ fontWeight: '500', fontSize: '25px' }}>{this.state.TotalSuctionPumpUsageHour}</span> <span style={{ fontSize: '25px', paddingLeft: '60px' }}>મીનીટ </span> <span style={{ fontWeight: '500', fontSize: '25px' }}>{this.state.TotalSuctionPumpUsageMinutes}</span> </td>

                                </tr>
                            </table>
                        </div>
                        <div className="col-md-12" style={{ marginTop: '1%', textAlign: 'left' }}>
                            <span style={{ fontSize: '25px' }}><span style={{ paddingRight: '5px' }}>૪.</span>સદર વોર્ડની ડ્રેનેજ લાઇનની ડિશીલ્ટીંગ ડિચોકીંગની નીચે મુજબની કામગીરી કરેલ છે.તથા કુલ <span style={{ fontWeight: '500', fontSize: '25px', borderBottom: '2px dotted' }}>{this.state.TotalDrainageMeter}</span> મીટર ડ્રેનેજલાઈન અને <span style={{ fontWeight: '500', fontSize: '25px', borderBottom: '2px dotted' }}>{this.state.TotalNoOfDranageLine}</span> નંગ
                                મશીનનહોલની સફાઈની કામગીરી કરેલ છે.</span>
                        </div>
                        <div className="col-md-12" style={{ marginTop: '1%' }}>
                            <table style={{ border: '1px solid' }} className="table table-bordered">
                                <tr>
                                    <td style={{ border: '1px solid black', width: '10%' }}><span style={{ fontSize: '25px' }}>અનુ ક્રમાંક</span></td>
                                    <td style={{ border: '1px solid black', width: '35%' }}><span style={{ fontSize: '25px' }}>ડ્રેનેજ લાઇન સાઈઝ ટેન્ડર</span></td>
                                    <td style={{ border: '1px solid black', width: '20%' }}><span style={{ fontSize: '25px' }}>ટેન્ડરના <span style={{ fontWeight: 'bold', fontSize: '25px' }}>KPI</span> મુજબ નો ટાર્ગેટ</span></td>
                                    <td style={{ border: '1px solid black', width: '35%' }}><span style={{ fontSize: '25px' }}>કામગીરીના સ્થળ ઉપર થયેલ ખરેખર કામગીરી</span></td>
                                </tr>
                                <tr>
                                    <td style={{ border: '1px solid black', width: '10%' }}><span style={{ fontSize: '25px' }}>૧</span></td>
                                    <td style={{ border: '1px solid black', width: '35%', textAlign: 'left' }}><span style={{ fontSize: '25px' }}>૧૫૦ એમ.એમ. થી ૩૦૦ એમ.એમ. ડાયા મીટર</span></td>
                                    <td style={{ border: '1px solid black', width: '20%', textAlign: 'left' }}><span style={{ fontSize: '25px' }}>૬૦ મીટર પ્રતિ કલાક</span></td>
                                    <td style={{ border: '1px solid black', width: '35%' }}><span style={{ fontWeight: '500', fontSize: '25px' }}>{this.state.DrainageLine150to300mmDiaMeter}</span></td>
                                </tr>
                                <tr>
                                    <td style={{ border: '1px solid black', width: '5%' }}><span style={{ fontSize: '25px' }}>૨</span></td>
                                    <td style={{ border: '1px solid black', width: '35%', textAlign: 'left' }}><span style={{ fontSize: '25px' }}>૩૦૧ એમ.એમ. થી ૬૦૦ એમ.એમ. ડાયા મીટર</span></td>
                                    <td style={{ border: '1px solid black', width: '25%', textAlign: 'left' }}><span style={{ fontSize: '25px' }}>૪૦ મીટર પ્રતિ કલાક</span></td>
                                    <td style={{ border: '1px solid black', width: '35%' }}><span style={{ fontWeight: '500', fontSize: '25px' }}>{this.state.DrainageLine301to600mmDiaMeter}</span></td>
                                </tr>
                                <tr>
                                    <td style={{ border: '1px solid black', width: '10%' }}><span style={{ fontSize: '25px' }}>૩</span></td>
                                    <td style={{ border: '1px solid black', width: '35%', textAlign: 'left' }}><span style={{ fontSize: '25px' }}>૬૦૧ એમ.એમ. થી ૯૦૦ એમ.એમ. ડાયા મીટર</span></td>
                                    <td style={{ border: '1px solid black', width: '20%', textAlign: 'left' }}><span style={{ fontSize: '25px' }}>૩૦ મીટર પ્રતિ કલાક</span></td>
                                    <td style={{ border: '1px solid black', width: '35%' }}><span style={{ fontWeight: '500', fontSize: '25px' }}>{this.state.DrainageLine601to900mmDiaMeter}</span></td>
                                </tr>
                                <tr>
                                    <td style={{ border: '1px solid black', width: '5%' }}><span style={{ fontSize: '25px' }}>૪</span></td>
                                    <td style={{ border: '1px solid black', width: '35%', textAlign: 'left' }}><span style={{ fontSize: '25px' }}>૯૦૧ એમ.એમ. થી ૧૨૦૦ એમ.એમ. ડાયા મીટર</span></td>
                                    <td style={{ border: '1px solid black', width: '25%', textAlign: 'left' }}><span style={{ fontSize: '25px' }}>૧૦ મીટર પ્રતિ કલાક</span></td>
                                    <td style={{ border: '1px solid black', width: '35%' }}><span style={{ fontWeight: '500', fontSize: '25px' }}>{this.state.DrainageLine901to1200mmDiaMeter}</span></td>
                                </tr>
                            </table>
                        </div>
                        <div className="col-md-12" style={{ marginTop: '1%', textAlign: 'left' }}>
                            <span style={{ fontSize: '25px' }}><span style={{ paddingRight: '5px' }}>૫.</span>કામગીરીના સ્થળ ઉપર જોવા મળેલ મશીનના ટેકનીકલ પેરામીટરની વિગત</span>
                        </div>
                        <div className="col-md-12" style={{ marginTop: '1%' }}>
                            <table style={{ border: '1px solid' }} className="table table-bordered">
                                <tr>
                                    <td style={{ border: '1px solid black', width: '10%' }}><span style={{ fontSize: '25px' }}>અનુ ક્રમાંક</span></td>
                                    <td style={{ border: '1px solid black', width: '35%' }}><span style={{ fontSize: '25px' }}>ટેકનીકલ પેરામીટર</span></td>
                                    <td style={{ border: '1px solid black', width: '20%' }}><span style={{ fontSize: '25px' }}>ટેન્ડર મુજબ જરૂરિયાત કેપેસીટી વૅલ્યુ </span></td>
                                    <td style={{ border: '1px solid black', width: '35%' }}><span style={{ fontSize: '25px' }}>કામગીરીના સ્થળ ઉપર ખરેખર મળેલ કેપેસીટી</span></td>
                                </tr>
                                <tr>
                                    <td style={{ border: '1px solid black', width: '10%' }}><span style={{ fontSize: '25px' }}>૧</span></td>
                                    <td style={{ border: '1px solid black', width: '35%', textAlign: 'left' }}><span style={{ fontSize: '25px' }}>જેટિગ સીસ્ટમ</span></td>
                                    <td style={{ border: '1px solid black', width: '20%', textAlign: 'left' }}><span style={{ fontSize: '25px' }}>૧૫૦ બાર</span></td>
                                    <td style={{ border: '1px solid black', width: '35%' }}><span style={{ fontWeight: '500', fontSize: '25px' }}>{this.state.JettingSystem}</span></td>
                                </tr>
                                <tr>
                                    <td style={{ border: '1px solid black', width: '10%' }}><span style={{ fontSize: '25px' }}>૨</span></td>
                                    <td style={{ border: '1px solid black', width: '35%', textAlign: 'left' }}><span style={{ fontSize: '25px' }}>સકશન સીસ્ટમ</span></td>
                                    <td style={{ border: '1px solid black', width: '20%', textAlign: 'left' }}><span style={{ fontSize: '25px' }}>૫૦૦૦ ક્યુબીક મી કલાક</span></td>
                                    <td style={{ border: '1px solid black', width: '35%' }}><span style={{ fontWeight: '500', fontSize: '25px' }}>{this.state.SuctionSystem}</span></td>
                                </tr>
                                <tr>
                                    <td style={{ border: '1px solid black', width: '10%' }}><span style={{ fontSize: '25px' }}>૩</span></td>
                                    <td style={{ border: '1px solid black', width: '35%', textAlign: 'left' }}><span style={{ fontSize: '25px' }}>સકશન હોઝ ૫ ડાયા મીટર - ૩ મીટર લંબાઈ</span></td>
                                    <td style={{ border: '1px solid black', width: '20%', textAlign: 'left' }}><span style={{ fontSize: '25px' }}>૧૦ નંગ</span></td>
                                    <td style={{ border: '1px solid black', width: '35%' }}><span style={{ fontWeight: '500', fontSize: '25px' }}>{this.state.SuctionHose5Diameter3MeterLong}</span></td>
                                </tr>
                                <tr>
                                    <td style={{ border: '1px solid black', width: '10%' }}><span style={{ fontSize: '25px' }}>૪</span></td>
                                    <td style={{ border: '1px solid black', width: '35%', textAlign: 'left' }}><span style={{ fontSize: '25px' }}>જેટિગ હોઝ - ૩૨ એમ.એમ. ડાયા, - ૧૨૦ મી લંબાઈ </span></td>
                                    <td style={{ border: '1px solid black', width: '20%', textAlign: 'left' }}><span style={{ fontSize: '25px' }}>૧ નંગ</span></td>
                                    <td style={{ border: '1px solid black', width: '35%' }}><span style={{ fontWeight: '500', fontSize: '25px' }}>{this.state.JettingHose32Diameter120MeterLong}</span></td>
                                </tr>
                                <tr>
                                    <td style={{ border: '1px solid black', width: '10%' }}><span style={{ fontSize: '25px' }}>પ</span></td>
                                    <td style={{ border: '1px solid black', width: '35%', textAlign: 'left' }}><span style={{ fontSize: '25px' }}>જેટિગ નોઝલ : ડીચોકીગ નોઝલ</span></td>
                                    <td style={{ border: '1px solid black', width: '20%', textAlign: 'left' }}><span style={{ fontSize: '25px' }}>૧ નંગ</span></td>
                                    <td style={{ border: '1px solid black', width: '35%' }}><span style={{ fontWeight: '500', fontSize: '25px' }}>{this.state.JettingNozzleDeChockingNozzle}</span></td>
                                </tr>
                                <tr>
                                    <td style={{ border: '1px solid black', width: '10%' }}><span style={{ fontSize: '25px' }}>૬</span></td>
                                    <td style={{ border: '1px solid black', width: '35%', textAlign: 'left' }}><span style={{ fontSize: '25px' }}>ડી શીલ્ટીગ નોઝલ - ફ્લેટ નોઝલ</span></td>
                                    <td style={{ border: '1px solid black', width: '20%', textAlign: 'left' }}><span style={{ fontSize: '25px' }}>૧ નંગ</span></td>
                                    <td style={{ border: '1px solid black', width: '35%' }}><span style={{ fontWeight: '500', fontSize: '25px' }}>{this.state.DeSiltingNozzleFlatNozzle}</span></td>
                                </tr>
                                <tr>
                                    <td style={{ border: '1px solid black', width: '10%' }}><span style={{ fontSize: '25px' }}>૭</span></td>
                                    <td style={{ border: '1px solid black', width: '35%', textAlign: 'left' }}><span style={{ fontSize: '25px' }}>બેલેન્સ નોઝલ બોમ્બ નોઝલ</span></td>
                                    <td style={{ border: '1px solid black', width: '20%', textAlign: 'left' }}><span style={{ fontSize: '25px' }}>૧ નંગ</span></td>
                                    <td style={{ border: '1px solid black', width: '35%' }}><span style={{ fontWeight: '500', fontSize: '25px' }}>{this.state.BalanceNozzleBombNozzle}</span></td>
                                </tr>

                            </table>
                        </div>
                        <div className="col-md-12" style={{ marginTop: '1%', textAlign: 'left' }}>
                            <span style={{ fontSize: '25px' }}><span style={{ paddingRight: '5px' }}>૬.</span>સાઈટ ઉપર હાજર સ્ટાફની વિગત</span>
                        </div>
                        <div className="col-md-12" style={{ marginTop: '1%', textAlign: 'left' }}>
                            <span style={{ fontSize: '25px' }}>(i)ડ્રાઇવરનું નામ : <span style={{ fontWeight: '500', fontSize: '25px', borderBottom: '2px dotted' }}>{this.state.DriverName}</span> (ii)ઓપરેટરનું નામ : <span style={{ fontWeight: '500', fontSize: '25px', borderBottom: '2px dotted' }}>{this.state.MultipleHelperName}</span> (iii)સુપરવાઇઝરનું નામ : <span style={{ fontWeight: '500', fontSize: '25px', borderBottom: '2px dotted' }}>{this.state.SupervisorName}</span></span>
                        </div>
                        <div className="col-md-12" style={{ marginTop: '1%', textAlign: 'left' }}>
                            <span style={{ fontSize: '25px' }}><span>૬.</span>સ્ટાફને આપેલ સુરક્ષા સાધનો(PPE) અને અન્ય વિગત, જો હોય તો (✔) અને જો ન હોય તો (✘) માર્ક કરવુ.</span>
                        </div>
                        <div className="col-md-12" style={{ marginTop: '1%', textAlign: 'left' }}>
                            <span style={{ fontSize: '25px' }}>(i) સેફટી શુઝ : {(this.state.IsSafetyShoesChecked) ? (<i style={{ color: 'black' }} className="icon-xl far fa-check-square"></i>) : (<i style={{ color: 'black' }} className="icon-xl far fa-window-close"></i>)} (ii) હેન્ડ ગ્લોવ્ઝ : {(this.state.IsHandGlovesChecked) ? (<i style={{ color: 'black' }} className="icon-xl far fa-check-square"></i>) : (<i style={{ color: 'black' }} className="icon-xl far fa-window-close"></i>)} (iii) હેલ્મેટ : {(this.state.IsHelmetChecked) ? (<i style={{ color: 'black' }} className="icon-xl far fa-check-square"></i>) : (<i style={{ color: 'black' }} className="icon-xl far fa-window-close"></i>)} (iv) માસ્ક : {(this.state.IsMaskChecked) ? (<i style={{ color: 'black' }} className="icon-xl far fa-check-square"></i>) : (<i style={{ color: 'black' }} className="icon-xl far fa-window-close"></i>)} (v) લાઈટ રીફ્લેકટીવ જેકેટ : {(this.state.IsLightReflectiveJacketChecked) ? (<i style={{ color: 'black' }} className="icon-xl far fa-check-square"></i>) : (<i style={{ color: 'black' }} className="icon-xl far fa-window-close"></i>)} (vi) યુનિફોર્મ : {(this.state.IsUniformChecked) ? (<i style={{ color: 'black' }} className="icon-xl far fa-check-square"></i>) : (<i style={{ color: 'black' }} className="icon-xl far fa-window-close"></i>)} (vii) આઈ કાર્ડ : {(this.state.IsIDCardChecked) ? (<i style={{ color: 'black' }} className="icon-xl far fa-check-square"></i>) : (<i style={{ color: 'black' }} className="icon-xl far fa-window-close"></i>)} </span>
                        </div>
                        <div className="col-md-12" style={{ marginTop: '1%', textAlign: 'left' }}>
                            <span style={{ fontSize: '25px' }}><span style={{ paddingRight: '5px' }}>૭.</span>રીમાર્કસ(અન્ય ફોલ્ટની કામગીરીની વિગત):-</span>
                        </div>
                        <div className="col-md-12" style={{ marginTop: '1%', textAlign: 'left' }}>
                            <span style={{ fontSize: '25px' }}><span style={{ paddingRight: '5px' }}>૮.</span>ટેન્ડર લીકવીડીટી ડેમેજીસની કન્ડિશન મુજબ (પાના નં. ૧૦) જો કપાત હોય તો તેની વિગત :</span>
                        </div>
                        <div className="col-md-12" style={{ marginTop: '5%', textAlign: 'left' }}>
                            <span style={{ fontSize: '25px', paddingLeft: '1%' }}>મશીન સુપરવાઇઝર<span style={{ fontWeight: 'bold', fontSize: '25px', paddingLeft: '5px' }}>(MWHPL)</span></span>
                            <span style={{ fontSize: '25px', paddingLeft: '1%' }}>ટેકનીકલ સુપરવાઇઝર<span style={{ fontWeight: 'bold', fontSize: '25px', paddingLeft: '5px' }}>(........Ward)</span></span>
                            <span style={{ fontSize: '25px', paddingLeft: '1%' }}>આસી. એનજીનીઅર<span style={{ fontWeight: 'bold', fontSize: '25px', paddingLeft: '5px' }}>(........Ward)</span></span>
                            <span style={{ fontSize: '25px', paddingLeft: '1%' }}>આસી.સીટી.એન્જીનીઅર<span style={{ fontWeight: 'bold', fontSize: '25px', paddingLeft: '5px' }}>(........Ward)</span></span>
                        </div>
                        <div className="col-md-12" style={{ marginTop: '1%', textAlign: 'center' }}>
                            <span style={{ fontSize: '25px' }}> <span style={{ fontWeight: 'bold' }}>Special Note :</span><span style={{ paddingLeft: '5px' }}>As per Hon. Court order in any case, contractor is not allowed to enter/work inside the machinehole or sewer lines. Proper training & work knowledge shall be provided to all personals engaged for work by contractor.</span></span>
                        </div>
                    </div>
                </div>

                <div id="divToGUDMPrint" className="divToGUDMPrint" style={{ display: 'none' }}>

                    <div className="row">
                        <div className="col-md-2" >
                            <img src="/Images/GUDM_logo.png" style={{ height: '100px', width: '100px', textAlign: 'left' }}></img>
                        </div>
                        <div className="col-md-7" style={{ textAlign: 'center', marginTop: '10px' }}>
                            <div className="row">
                                <div className="col-md-12">
                                    <h3 style={{ fontWeight: 'bold', fontSize: '30px', marginBottom: '10px' }}>ગુજરાત શહેરી વિકાસ મિશન</h3>
                                </div>
                                <div className="col-md-12">
                                    <h3 style={{ fontWeight: 'bold', fontSize: '25px' }}>પર્ફોમન્સ સર્ટીફીકેટ</h3>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2" style={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                            <span style={{ fontWeight: 'bold', fontSize: '20px' }}>No.</span>
                            <span style={{ fontSize: '25px', borderBottom: '2px dotted', width: '100%' }}>{this.state.LogSheetNo}</span>
                        </div>
                        <div className="col-md-1">
                            <img src="/Images/azadi.png" style={{ height: '100px', width: '100px' }}></img>
                        </div>
                        <div className="col-md-12" style={{ marginTop: '1%', textAlign: 'left' }}>
                            <span style={{ fontWeight: '500', fontSize: '25px' }}>આથી મે. મેટ્રો વેસ્ટ હેન્ડલીંગ પ્રા. લી.ને સર્ટીફીકેટ આપવામાં આવે છે કે, ડીશીલ્ટીંગ મશીન રજીસ્‍ટ્રેશન નંબર <span style={{ borderBottom: '2px dotted' }}>{this.state.VehicleNo}</span> દ્રારા તા <span style={{ borderBottom: '2px dotted' }}>{this.state.BookingDate}</span></span>
                            <span style={{ fontWeight: '500', fontSize: '25px' }}> ના રોજ <span style={{ fontWeight: '500', fontSize: '25px', borderBottom: '2px dotted' }}>{this.state.MuncipalityName}</span> નગરપાલિકા <span style={{ fontWeight: '500', fontSize: '25px', borderBottom: '2px dotted' }}>{this.state.WardName}</span> શીફ્ટ <span style={{ fontWeight: '500', fontSize: '25px', borderBottom: '2px dotted' }}>{this.state.Shift}</span> દરમ્યાન નીચે મુજબની વિગતથી કામગીરી કરેલ છે.</span>
                        </div>
                        <div className="col-md-12" style={{ marginTop: '1%', textAlign: 'left' }}>
                            <span style={{ fontWeight: '500', fontSize: '25px' }}><span style={{ paddingRight: '5px' }}>૧.</span> ઝોનઃ <span style={{ fontWeight: '500', fontSize: '25px', borderBottom: '2px dotted' }}>{this.state.ZoneName}</span> વોર્ડ : <span style={{ fontWeight: '500', fontSize: '25px', borderBottom: '2px dotted' }}>{this.state.WardName}</span> કામગીરીનું સ્થળ: <span style={{ fontWeight: '500', fontSize: '25px', borderBottom: '2px dotted' }}>{this.state.Locality + (this.state.LocationDetail != '' ? ('-' + this.state.LocationDetail) : '')}</span></span>
                        </div>

                        <div className="col-md-12" style={{ marginTop: '1%', textAlign: 'left' }}>
                            <span style={{ fontWeight: '500', fontSize: '25px' }}><span style={{ paddingRight: '5px' }}>ર.</span> એન્જીનિયર ઇન્ચાર્જનું નામ: <span style={{ fontWeight: '500', fontSize: '25px', borderBottom: '2px dotted' }}>{this.state.EngineerName}</span> હોદ્દો <span style={{ fontWeight: '500', fontSize: '25px', borderBottom: '2px dotted' }}>{this.state.EngineerDesignation}</span></span>
                        </div>

                        <div className="col-md-12" style={{ marginTop: '1%', textAlign: 'left' }}>
                            <span style={{ fontWeight: '500', fontSize: '25px' }}><span style={{ paddingRight: '5px' }}>૩.</span> પાર્કિંગ સ્થળ: <span style={{ fontWeight: '500', fontSize: '25px', borderBottom: '2px dotted' }}>{this.state.ParkingLocationName}</span></span>
                        </div>
                        <div className="col-md-12" style={{ marginTop: '1%', textAlign: 'left' }}>
                            <span style={{ fontSize: '25px' }}><span style={{ paddingRight: '5px' }}>૪.</span> કામગીરીની વિગત : <span style={{ fontWeight: '500', fontSize: '25px', borderBottom: '2px dotted' }}>{this.state.Locality}</span></span>
                        </div>
                        <div className="col-md-12" style={{ marginTop: '1%' }}>
                            <table style={{ border: '1px solid' }} className="table table-bordered">
                                <tr>
                                    <td style={{ border: '1px solid black', width: '10%' }}><span style={{ fontSize: '25px' }}>અનુ ક્રમાંક</span></td>
                                    <td style={{ border: '1px solid black', width: '20%' }}><span style={{ fontSize: '25px' }}>વિગત</span></td>
                                    <td style={{ border: '1px solid black', width: '13.75%' }}><span style={{ fontSize: '25px' }}>સમય</span></td>
                                    <td style={{ border: '1px solid black', width: '13.75%', textAlign: 'center' }}><span style={{ fontSize: '25px' }}>ઓડોમીટર</span></td>
                                    <td style={{ border: '1px solid black', width: '20%' }}><span style={{ fontSize: '25px' }}>વિગત</span></td>
                                    <td style={{ border: '1px solid black', width: '13.75%' }}><span style={{ fontSize: '25px' }}>સમય</span></td>
                                    <td style={{ border: '1px solid black', width: '13.75%', textAlign: 'center' }}><span style={{ fontSize: '25px' }}>ઓડોમીટર</span></td>
                                </tr>
                                <tr>
                                    <td style={{ border: '1px solid black', width: '5%' }}><span style={{ fontSize: '25px' }}>૧</span></td>
                                    <td style={{ border: '1px solid black', width: '20%', textAlign: 'left' }}><span style={{ fontSize: '25px' }}>મશીન પાર્કિંગ થી નિકળ્યાનો સમય</span></td>
                                    <td style={{ border: '1px solid black', width: '13.75%' }}><span style={{ fontWeight: '500', fontSize: '25px' }}>{this.state.VehicleLeaveParkingTime2}</span></td>
                                    <td style={{ border: '1px solid black', width: '13.75%', textAlign: 'center' }}><span style={{ fontWeight: '500', fontSize: '25px' }}>{this.state.VehicleLeaveParkingKiloMeter}</span></td>
                                    <td style={{ border: '1px solid black', width: '20%', textAlign: 'left' }}><span style={{ fontSize: '25px' }}>મશીન કામગીરીના સ્થળ ઉપર પહોંચ્યાનો સમય</span></td>
                                    <td style={{ border: '1px solid black', width: '13.75%' }}><span style={{ fontWeight: '500', fontSize: '25px' }}>{this.state.VehicleReachedLocationTime2}</span></td>
                                    <td style={{ border: '1px solid black', width: '13.75%', textAlign: 'center' }}><span style={{ fontWeight: '500', fontSize: '25px' }}>{this.state.VehicleReachedLocationKiloMeter}</span></td>
                                </tr>
                                <tr>
                                    <td style={{ border: '1px solid black', width: '10%' }}><span style={{ fontSize: '25px' }}>ર</span></td>
                                    <td style={{ border: '1px solid black', width: '20%', textAlign: 'left' }}><span style={{ fontSize: '25px' }}>મશીન કામગીરીના સ્થળ ઉપરથી નિકળ્યાનો સમય</span></td>
                                    <td style={{ border: '1px solid black', width: '13.75%' }}><span style={{ fontWeight: '500', fontSize: '25px' }}>{this.state.VehicleReturnLocationTime2}</span></td>
                                    <td style={{ border: '1px solid black', width: '13.75%', textAlign: 'center' }}><span style={{ fontWeight: '500', fontSize: '25px' }}>{this.state.VehicleReturnLocationKiloMeter}</span></td>
                                    <td style={{ border: '1px solid black', width: '20%', textAlign: 'left' }}><span style={{ fontSize: '25px' }}>મશીન પાર્કિંગ ઉપર પહોચ્યાંનો સમય</span></td>
                                    <td style={{ border: '1px solid black', width: '13.75%' }}><span style={{ fontWeight: '500', fontSize: '25px' }}>{this.state.VehicleReachedParkingTime2}</span></td>
                                    <td style={{ border: '1px solid black', width: '13.75%', textAlign: 'center' }}><span style={{ fontWeight: '500', fontSize: '25px' }}>{this.state.VehicleReachedParkingKiloMeter}</span></td>
                                </tr>
                                <tr>
                                    <td style={{ border: '1px solid black', width: '10%' }}><span style={{ fontSize: '25px' }}>૩</span></td>
                                    <td style={{ border: '1px solid black', width: '20%', textAlign: 'left' }}><span style={{ fontSize: '25px' }}>કુલ સમય</span></td>
                                    <td style={{ border: '1px solid black', width: '13.75%', textAlign: 'left' }} colspan='2'><span style={{ fontSize: '25px' }}>કલાક </span><span style={{ fontWeight: '500', fontSize: '25px' }}>{this.state.TotalHours}</span> <span style={{ fontSize: '25px', paddingLeft: '120px' }}>મીનીટ </span><span style={{ fontWeight: '500', fontSize: '25px' }}>{this.state.TotalMinutes}</span> </td>
                                    {/* <td style={{ border: '1px solid black', width: '13.75%' }}><span style={{ fontSize: '25px' }}>મીનીટ</span></td> */}
                                    <td style={{ border: '1px solid black', width: '20%', textAlign: 'left' }}><span style={{ fontSize: '25px' }}>કુલ કિલોમીટર રીડીગ  </span></td>
                                    <td style={{ border: '1px solid black', width: '13.75%' }} colspan='2'><span style={{ fontWeight: '500', fontSize: '25px' }}>{this.state.TotalKilometer}</span></td>
                                    {/* <td style={{ border: '1px solid black', width: '15%' }}><span style={{ fontSize: '25px' }}></span></td> */}
                                </tr>
                            </table>
                        </div>
                        <div className="col-md-12" style={{ marginTop: '1%', textAlign: 'left' }}>
                            <span style={{ fontSize: '25px' }}><span style={{ paddingRight: '5px' }}>૫.</span> કરેલ કામગીરી :</span>
                        </div>
                        <div className="col-md-12" style={{ marginTop: '1%' }}>
                            <table style={{ border: '1px solid' }} className="table table-bordered">
                                <tr>
                                    <td style={{ border: '1px solid black', width: '10%' }}><span style={{ fontSize: '25px' }}>અનુ ક્રમાંક</span></td>
                                    <td style={{ border: '1px solid black', width: '35%' }}><span style={{ fontSize: '25px' }}>ડ્રેનેજ લાઇન સાઈઝ ટેન્ડર</span></td>
                                    <td style={{ border: '1px solid black', width: '35%' }}><span style={{ fontSize: '25px' }}>કામગીરીના સ્થળ ઉપર થયેલ ખરેખર કામગીરી</span></td>
                                </tr>
                                <tr>
                                    <td style={{ border: '1px solid black', width: '5%' }}><span style={{ fontSize: '25px' }}>૧</span></td>
                                    <td style={{ border: '1px solid black', width: '35%', textAlign: 'left' }}><span style={{ fontSize: '25px' }}>૩૦૧ એમ.એમ. થી ૬૦૦ એમ.એમ. ડાયા મીટર</span></td>
                                    <td style={{ border: '1px solid black', width: '35%' }}><span style={{ fontWeight: '500', fontSize: '25px' }}>{this.state.DrainageLine301to600mmDiaMeter}</span></td>
                                </tr>
                                <tr>
                                    <td style={{ border: '1px solid black', width: '10%' }}><span style={{ fontSize: '25px' }}>૨</span></td>
                                    <td style={{ border: '1px solid black', width: '35%', textAlign: 'left' }}><span style={{ fontSize: '25px' }}>૬૦૧ એમ.એમ. થી ૯૦૦ એમ.એમ. ડાયા મીટર</span></td>
                                    <td style={{ border: '1px solid black', width: '35%' }}><span style={{ fontWeight: '500', fontSize: '25px' }}>{this.state.DrainageLine601to900mmDiaMeter}</span></td>
                                </tr>
                                <tr>
                                    <td style={{ border: '1px solid black', width: '5%' }}><span style={{ fontSize: '25px' }}>૩</span></td>
                                    <td style={{ border: '1px solid black', width: '35%', textAlign: 'left' }}><span style={{ fontSize: '25px' }}>૯૦૧ એમ.એમ. થી ૧૨૦૦ એમ.એમ. ડાયા મીટર</span></td>
                                    <td style={{ border: '1px solid black', width: '35%' }}><span style={{ fontWeight: '500', fontSize: '25px' }}>{this.state.DrainageLine901to1200mmDiaMeter}</span></td>
                                </tr>
                            </table>
                        </div>
                        <div className="col-md-12" style={{ marginTop: '1%', textAlign: 'left' }}>
                            <span style={{ fontSize: '25px' }}>સદર વોર્ડની ડ્રેનેજ લાઇનની ડિશીલ્ટીંગ / ડિચોકીંગની કામગીરી માં કુલ <span style={{ fontWeight: '500', fontSize: '25px', borderBottom: '2px dotted' }}>{this.state.TotalDrainageMeter}</span> મીટર ડ્રેનેજલાઈન અને <span style={{ fontWeight: '500', fontSize: '25px', borderBottom: '2px dotted' }}>{this.state.TotalNoOfDranageLine}</span> નંગ મશીનનહોલની સફાઈની કામગીરી સંતોષકારક પૂર્ણ કરેલ છે.</span>
                        </div>

                        <div className="col-md-12" style={{ marginTop: '1%', textAlign: 'left' }}>
                            <span style={{ fontSize: '25px' }}><span style={{ paddingRight: '5px' }}>૬.</span>કામગીરી બંધ રાખવાની વિગત: રિસેસ <span style={{ fontWeight: '500', fontSize: '25px', borderBottom: '2px dotted' }}>{this.state.LunchStartTime}</span> થી <span style={{ fontWeight: '500', fontSize: '25px', borderBottom: '2px dotted' }}>{this.state.LunchEndTime}</span> ;કુલ <span style={{ fontWeight: '500', fontSize: '25px', borderBottom: '2px dotted' }}>{this.state.TotalLunchTime}</span> કલાક</span>
                        </div>

                        <div className="col-md-12" style={{ marginTop: '1%', textAlign: 'left' }}>
                            <span style={{ fontSize: '25px' }}><span style={{ paddingRight: '5px' }}>૭.</span>મશીન અન્ય કારણસર બંધ રહ્યાની વિગત <span style={{ fontWeight: '500', fontSize: '25px', borderBottom: '2px dotted' }}>{this.state.MachineNotWorkFromTime}</span> થી <span style={{ fontWeight: '500', fontSize: '25px', borderBottom: '2px dotted' }}>{this.state.MachineNotWorkToTime}</span> ;કુલ <span style={{ fontWeight: '500', fontSize: '25px', borderBottom: '2px dotted' }}>{this.state.MachineNotWorkTotalTime}</span> કલાક</span>
                        </div>

                        <div className="col-md-12" style={{ marginTop: '1%', textAlign: 'left' }}>
                            <span style={{ fontSize: '25px' }}><span style={{ paddingRight: '5px' }}>૮.</span>અસાધારણ પરિસ્થિતિ ની વિગત:  અસાધારણ પરિસ્થિતિ <span style={{ fontWeight: '500', fontSize: '25px', borderBottom: '2px dotted' }}>{this.state.ExtraordinaryDetails}</span> સ્થળ <span style={{ fontWeight: '500', fontSize: '25px', borderBottom: '2px dotted' }}>{this.state.ExtraordinaryArea}</span> મેન હોલ ની સંખ્યા <span style={{ fontWeight: '500', fontSize: '25px', borderBottom: '2px dotted' }}>{this.state.ExtraordinaryMachineHoles}</span></span>
                        </div>

                        <div className="col-md-12" style={{ marginTop: '1%', textAlign: 'left' }}>
                            <span style={{ fontSize: '25px' }}><span style={{ paddingRight: '5px' }}>૯.</span>સાઈટ ઉપર હાજર સ્ટાફની વિગત</span>
                        </div>
                        <div className="col-md-12" style={{ marginTop: '1%', textAlign: 'left' }}>
                            <span style={{ fontSize: '25px' }}>(i)ડ્રાઇવરનું નામ : <span style={{ fontWeight: '500', fontSize: '25px', borderBottom: '2px dotted' }}>{this.state.DriverName}</span> (ii)ઓપરેટરનું નામ : <span style={{ fontWeight: '500', fontSize: '25px', borderBottom: '2px dotted' }}>{this.state.MultipleHelperName}</span> (iii)સુપરવાઇઝરનું નામ : <span style={{ fontWeight: '500', fontSize: '25px', borderBottom: '2px dotted' }}>{this.state.AssignTaskSupervisorName}</span></span>
                        </div>
                        <div className="col-md-12" style={{ marginTop: '1%', textAlign: 'left' }}>
                            <span style={{ fontSize: '25px' }}><span>૧૦.</span>સ્ટાફને આપેલ સુરક્ષા સાધનો(PPE) અને અન્ય વિગત, જો હોય તો (✔) અને જો ન હોય તો (✘) માર્ક કરવુ.</span>
                        </div>
                        <div className="col-md-12" style={{ marginTop: '1%', textAlign: 'left' }}>
                            <span style={{ fontSize: '25px' }}>(i) સેફટી શુઝ : {(this.state.IsSafetyShoesChecked) ? (<i style={{ color: 'black' }} className="icon-xl far fa-check-square"></i>) : (<i style={{ color: 'black' }} className="icon-xl far fa-window-close"></i>)} (ii) હેન્ડ ગ્લોવ્ઝ : {(this.state.IsHandGlovesChecked) ? (<i style={{ color: 'black' }} className="icon-xl far fa-check-square"></i>) : (<i style={{ color: 'black' }} className="icon-xl far fa-window-close"></i>)} (iii) હેલ્મેટ : {(this.state.IsHelmetChecked) ? (<i style={{ color: 'black' }} className="icon-xl far fa-check-square"></i>) : (<i style={{ color: 'black' }} className="icon-xl far fa-window-close"></i>)} (iv) માસ્ક : {(this.state.IsMaskChecked) ? (<i style={{ color: 'black' }} className="icon-xl far fa-check-square"></i>) : (<i style={{ color: 'black' }} className="icon-xl far fa-window-close"></i>)} (v) લાઈટ રીફ્લેકટીવ જેકેટ : {(this.state.IsLightReflectiveJacketChecked) ? (<i style={{ color: 'black' }} className="icon-xl far fa-check-square"></i>) : (<i style={{ color: 'black' }} className="icon-xl far fa-window-close"></i>)} (vi) યુનિફોર્મ : {(this.state.IsUniformChecked) ? (<i style={{ color: 'black' }} className="icon-xl far fa-check-square"></i>) : (<i style={{ color: 'black' }} className="icon-xl far fa-window-close"></i>)} (vii) આઈ કાર્ડ : {(this.state.IsIDCardChecked) ? (<i style={{ color: 'black' }} className="icon-xl far fa-check-square"></i>) : (<i style={{ color: 'black' }} className="icon-xl far fa-window-close"></i>)} </span>
                        </div>


                        <div className="col-md-12" style={{ marginTop: '1%', textAlign: 'left' }}>
                            <span style={{ fontSize: '25px' }}><span>૧૧.</span>Sludge નું ડીસપોઝલ નગરપાલિકા એ બતાવેલ જગ્યાએ ખાલી કરેલ છે હા {(this.state.IsSludgeDisposalAreaYes) ? (<i style={{ color: 'black' }} className="icon-xl far fa-check-square"></i>) : (<i style={{ color: 'black' }} className="icon-xl far fa-window-close"></i>)} ના {(this.state.IsSludgeDisposalAreaNo) ? (<i style={{ color: 'black' }} className="icon-xl far fa-check-square"></i>) : (<i style={{ color: 'black' }} className="icon-xl far fa-window-close"></i>)}</span>
                        </div>

                        <div className="col-md-12" style={{ marginTop: '1%', textAlign: 'left' }}>
                            <span style={{ fontSize: '25px' }}><span style={{ paddingRight: '5px' }}>૧૨.</span>રીમાર્કસ(અન્ય ફોલ્ટની કામગીરીની વિગત):-</span>
                        </div>
                        <div className="col-md-12" style={{ marginTop: '5%', textAlign: 'right' }}>
                            <span style={{ fontSize: '25px', paddingLeft: '1%' }}>મશીન સુપરવાઇઝર ઇન્ચાર્જ ની સહી<span style={{ fontWeight: 'bold', fontSize: '25px', paddingLeft: '5px' }}></span></span>
                            <span style={{ fontSize: '25px', paddingRight: '15%' }}><span style={{ fontWeight: 'bold', fontSize: '25px', paddingLeft: '5px' }}></span></span>
                            <span style={{ fontSize: '25px', paddingLeft: '1%' }}>ઈન્જીનિયર ઇન્ચાર્જ ની સહી<span style={{ fontWeight: 'bold', fontSize: '25px', paddingLeft: '5px' }}></span></span>
                            <span style={{ fontSize: '25px', paddingRight: '15%' }}><span style={{ fontWeight: 'bold', fontSize: '25px', paddingLeft: '5px' }}></span></span>
                        </div>
                        <div className="col-md-12" style={{ marginTop: '1%', textAlign: 'center' }}>
                            <span style={{ fontSize: '25px' }}> <span style={{ fontWeight: 'bold' }}>Special Note :</span><span style={{ paddingLeft: '5px' }}>As per Hon. Court order in any case, contractor is not allowed to enter/work inside the machinehole or sewer lines. Proper training & work knowledge shall be provided to all personals engaged for work by contractor.</span></span>
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}
export default withCookies(viewadmintask);